import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';


import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Refresh from '@mui/icons-material/Refresh'
//import Masonry from '@mui/lab/Masonry';
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import RequestBlockSquare from './utility/RequestBlockSquare';
import CustomFilterMenu from './utility/CustomFilterMenu';
import Picker from './utility/Pickers'
import Tooltip from '@mui/material/Tooltip'
import { Button, Grid, Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import FilterSourceData from './utility/FilterSourceData';

import logoNotFound from '../logos/stars.png'

import TagsFilter from './utility/TagsFilter';

import statiRiconosciuti from './helpers/statiRiconosciuti';

import PropTypes from 'prop-types';


import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as ChartTooltip,
    ScatterChart, Scatter,
    ResponsiveContainer,
    Cell

} from "recharts";



const axios = require('axios');

const intervalTick = 15000; //[ms]

//const marginsHistory = { marginTop: '10em', marginBottom: '2em', marginLeft: '2em', marginRight: '2em' };

const marginsHistory = { marginTop: 96, marginBottom: 32, marginLeft: 32, marginRight: 32 };



const colors = [
    '#4287f5',
    '#f5c842',
    '#f5ac6c',
    '#42dbb5',
    '#c70a0a',
    '#e32282',
    '#656565',
    '#0d1aa3',
    '#c4db5a',
    '#1d802f',
]
export default function Traffic({ auth, parentForceRefresh, itemsToShow, inDashBoard = false }) {

    // init 
    const now = new Date();
    const todayEnd = (new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)).getTime();

    const navbarRef = useRef();

    // query params
    const queryParams = new URLSearchParams(window.location.search);

    // Stato
    // app padre sta prcurando le credenziali e i dati utente
    const [credentials, setCredentials] = useState({
        ...auth.userState,
        attached_projects: auth.userState.userItem ? auth.userState.userItem.attached_projects : [],
    });

    const historyStart = getThatDaysBackMidnight(now, 31 * 6);

    const [items, setItems] = useState({
        items: [],
        chartDataItems: [],
        meanQueueTime: 0,
        maxQueueTime: 0,
        tagOrderedObj: {},
        tagAllObj: {},
        tagOrderedList: [],
        blockToShowCompl: inDashBoard ? 2 : 12,
        blockToShowFailed: inDashBoard ? 2 : 12,
        retrievingData: true
    }); // stato progetti

    const [refresh, setRefresh] = useState(true); // stato progetti

    // stato filtri 
    const [menuListSelected, setListMenu] = useState([]);
    const [listaStatiSelected, setListaStati] = useState([]);
    //const [listaUsersSelected, setUsersStati] = useState([]);

    const [datePicker, setDatePicker] = useState({
        dateStart: parseDateQuery(queryParams.get('dateStart'), historyStart, 'start'),
        dateEnd: parseDateQuery(queryParams.get('dateEnd'), todayEnd, 'end')
    })

    const [origin, setOrigin] = useState(queryParams.get('reqid') || '');

    const showMoreItemsCompl = () => {
        setItems(items => { return { ...items, blockToShowCompl: items.blockToShowCompl + 12 } })
    }
    const showMoreItemsFailed = () => {
        setItems(items => { return { ...items, blockToShowFailed: items.blockToShowFailed + 12 } })
    }



    const setIntervalNode = () => {
        return setInterval(
            () => refreshPage(),
            intervalTick
        );
    }


    const changeItem = ({ reqId, newItem }) => {
        //console.log('change item ', newItem.status,);
        // first check for delete
        if (newItem.delete === 'delete') {
            setItems(items => {
                const tmpItems = [...items.items].filter(el => el.reqId !== reqId);
                return {
                    ...items,
                    items: tmpItems
                }
            })
        }
        else if (statiRiconosciuti.terminati.has(newItem.status)) {
            newItem.moved = true;
            setItems(items => {
                const tmpItems = [...items.items].map(el => {
                    if (el.reqId !== reqId) return el;
                    else return newItem
                })
                //console.log('item refreshed ', reqId, tmpItems)
                return {
                    ...items,
                    items: tmpItems
                }
            })
        }
    }



    // set auto refreh on each refresh
    useEffect(() => {
        const intervalState = setIntervalNode();
        return () => {
            clearInterval(intervalState);
        }
    }, []);



    // retrieve dinamo upon refresh
    useEffect(() => {
        console.log('fetch')
        if (credentials.userItem && credentials.userItem.attached_projects) {
            const listaProgetti = menuListSelected.length === 0 ? credentials.attached_projects : menuListSelected;
            if (listaProgetti.length > 0) {
                setItems({ ...items, retrievingData: true });
                axios({
                    method: "get",
                    url: 'https://api.pagination.com/vpc/operations',
                    headers: {
                        'user_id': credentials.ssmParams.USER_ID,
                        "content-type": "application/json",
                        "x-api-key": credentials.ssmParams.X_API_KEY,
                        "is_admin": credentials.isAdmin,
                        "running_traffic": true,
                        "datestart": datePicker.dateStart,
                        "dateend": datePicker.dateEnd,
                        "stati": [
                            'queued',
                            'queued (validated)',
                            'pagination in progress - running',
                            'pagination in progress - queued',
                        ].join('@')
                    }
                })
                    .then(resp => {
                        // console.log(resp.data)
                        const things = [...resp.data.items];
                        const data = [];
                        let meanQueueTime = 0; let maxQueueTime = 0;
                        let countItems = 0;

                        for (const el of things) {
                            if (el.origin && !isNaN(el.queuedTime)) {
                                for (const splitName in el.splitDoneObj) {
                                    const split = el.splitDoneObj[splitName];
                                    if (split.endTime && split.queuedTime) {
                                        const queueTime = Math.max(split.queuedTime / 1000 / 60, 0);
                                        meanQueueTime += queueTime;
                                        maxQueueTime = Math.max(maxQueueTime, queueTime)
                                        countItems++;
                                        data.push({ colourType: colors[parseInt(el.reqId.charCodeAt(0).toString().slice(-1))], t: split.endTime, queueTime: queueTime, humanTime: (new Date(split.endTime)).toString(), id: el.project_id })
                                    }
                                }
                            }
                        }
                        meanQueueTime = countItems > 0 ? meanQueueTime / countItems : 0;
                        setItems({ ...items, items: resp.data.items, maxQueueTime, meanQueueTime: meanQueueTime, chartDataItems: data, retrievingData: false, tagOrderedList: resp.data.tagOrderedList, tagOrderedObj: resp.data.tagOrderedObj, tagAllObj: resp.data.tagAllObj })
                    })
                    .catch(err => {
                        setItems({ ...items, retrievingData: false })
                        console.log(err)
                    });
            }
        }

    }, [credentials.attached_projects, refresh, menuListSelected, listaStatiSelected]); // si dovrebbe refreshare ogni volta che cambia lo stato di authObj


    // refresh quando l'app padre aggiorna i progetti dopo F5
    useEffect(() => {
        if (auth.userState.userItem) {
            setCredentials({ ...auth.userState, isFetching: auth.userState.isFetching, attached_projects: auth.userState.attached_projects })
        }
    }, [auth, auth.userState.isFetching, auth.userState.attached_projects]);

    // function that increase number refresh so that useEffect is called again
    function refreshPage() {
        setRefresh(refresh => !refresh)
    }


    // RENDER 
    return (

        <Stack direction='row' sx={{ margin: { ...marginsHistory } }} spacing={2}>

            <Stack direction='column' spacing={4} width='60%'>

                {/* {items.tagOrderedList.length > 0 && <TagsFilter tagOrderedObj={items.tagOrderedObj} tagOrderedList={items.tagOrderedList} handleClickTagChip={handleClickTagChip} />} */}

                <BlockList title='In progress'
                    requestList={filterItem({ items, validStati: statiRiconosciuti.running, origin, tagsObj: items.tagOrderedObj })}
                    inDashBoard={inDashBoard}
                    changeItem={changeItem}
                    user={auth.userState} attached_projects={auth.userState.attached_projects}
                />
                <BlockList title='Completed'
                    requestList={filterItem({ items, validStati: statiRiconosciuti.completati, origin, tagsObj: items.tagOrderedObj })}
                    inDashBoard={inDashBoard}
                    showMore={showMoreItemsCompl}
                    limit={items.blockToShowCompl}
                    changeItem={changeItem}
                    user={auth.userState} attached_projects={auth.userState.attached_projects}
                />
                <BlockList title='Failed'
                    requestList={filterItem({ items, validStati: statiRiconosciuti.falliti, origin, tagsObj: items.tagOrderedObj })}
                    inDashBoard={inDashBoard}
                    showMore={showMoreItemsFailed}
                    limit={items.blockToShowFailed}
                    changeItem={changeItem}
                    user={auth.userState} attached_projects={auth.userState.attached_projects}
                />
            </Stack>


            <Stack direction='row' spacing={1} width='40%'>
                <Stack direction='column' justifyContent='flex-start' spacing={1}>
                    <Button variant='contained' onClick={refreshPage} endIcon={<Refresh />}>
                        reload
                    </Button>
                    <Paper>
                        <CircularProgressWithLabel label={items.maxQueueTime} title='Max queue time [mins]' />
                    </Paper>
                    <Paper>
                        <CircularProgressWithLabel label={items.meanQueueTime} title='Avg queue time [mins]' />
                    </Paper>
                </Stack>

                <Paper sx={{ width: '80%', height: 413 }}>
                    <ResponsiveContainer width="95%" height={400}>
                        <ScatterChart
                            //  width={600}
                            //   height={400}
                            margin={{ top: 40, right: 40, bottom: 40, left: 50, }}
                        >
                            <CartesianGrid />
                            <XAxis
                                type="number"
                                dataKey="t"
                                name="split start time"
                                interval="preserveStartEnd"
                                domain={['dataMin - 1000', 'dataMax + 1000']}
                                tickFormatter={formatXAxis}
                                label={{ value: 'ora', position: 'bottom', offset: 5 }}

                            />
                            <YAxis type="number" dataKey="queueTime" name="queue time per split" unit="'" label={{ value: 'Tempo [min]', angle: -90, position: 'left', offset: 20 }} />
                            <ChartTooltip content={< CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />
                            <Scatter name="data" data={items.chartDataItems}  >

                                {items.chartDataItems.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.colourType} />
                                ))}

                            </Scatter>
                        </ScatterChart>
                    </ResponsiveContainer>


                </Paper>


            </Stack>

        </Stack >








    );


}



function getThatDaysBackMidnight(now, daysBack = 1) {
    const yesterdayId = now.getDate() - daysBack;
    const tempDate = new Date();
    tempDate.setDate(yesterdayId);
    tempDate.setHours(0, 0, 0, 0);
    return tempDate.getTime();
}

function BlockList({ title, requestList, showMore, limit, inDashBoard, changeItem, user, attached_projects }) {
    const breaks = { xs: 12, sm: 6, md: 6, lg: 4, xl: 4 }

    if (requestList.length > 0) {

        return (
            <Box sx={{}}>
                <Typography variant='h5' color='primary' sx={{ marginBottom: '0.5em', }}>
                    {title}
                </Typography>
                <Grid container spacing={2}
                    //justifyContent={inDashBoard ? "center" : undefined}
                    sx={{ flexGrow: 1 }}>
                    {requestList.slice(0, limit).map(request => {
                        //console.log(request.reqId, request.status)
                        return (
                            <Grid item key={request.reqId} {...breaks}  >
                                <RequestBlockSquare key={request.version} changeItem={changeItem} requestParent={request} user={user} attached_projects={attached_projects} />
                            </Grid>
                        )
                    }
                    )}
                </Grid>
                {requestList.length > limit && (
                    <Tooltip title="Show more">
                        <IconButton sx={{ position: 'relative', left: '50%', mb: '1em' }} onClick={showMore} >
                            <KeyboardArrowDownIcon />
                        </IconButton>
                    </Tooltip>
                )}



            </Box>
        )
    } else {
        return null
    }
}



function filterItem({ items, validStati, tagsObj, origin }) {

    //console.log('origin',origin)
    const originLower = origin ? origin.toLowerCase() : origin;

    return items.items.filter(item => {
        const itemOrigin = item.origin ? item.origin.toLowerCase() : '';
        const isStato = validStati.has(item.status);
        const isOrigin = (origin === '') || itemOrigin.indexOf(originLower) !== -1 || item.reqId === origin;

        let hasTags = false;

        if (!item.tagsObj || Object.keys(tagsObj).length === 0) hasTags = true;
        else {
            // console.log(el.key)
            try {
                if (item.tagsObj.some(el => tagsObj[el.name][0].active || (tagsObj[el.name] && tagsObj[el.name].filter(f => f.label === el.value && f.active).length > 0))) hasTags = true
            } catch (err) {
                console.log(err)
            }
        }
        //else if (item.tagsObj.some(el => tagsObj[el.key] && tagsObj[el.key].filter(f => f.active).length > 0)) hasTags = true;

        return isStato && isOrigin && hasTags;

    })

}

function parseDateQuery(date, alternative, type) {

    const numero = parseInt(date);
    if (isNaN(numero)) return alternative
    else return type === 'start' ? numero - 20 * 60 * 1000 : numero
}


const CustomTooltip = ({ active, payload }) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`t : ${formatToolTip(payload[0].payload.t, 't')}`}</p>
                <p className="label">{`queueTime : ${formatToolTip(payload[0].payload.queueTime)}`}</p>
                <p className="label">{`id : ${payload[0].payload.id}`}</p>

            </div>
        );
    }
    return null;
};

function formatXAxis(value) {
    // console.log(value)
    const timeNow = new Date(value);

    return `${timeNow.getHours()}:${timeNow.getMinutes()}:${timeNow.getSeconds()}`
}

function formatToolTip(value, name) {
    if (name === 't') return (new Date(value)).toString().replace('GMT+0100 (Ora standard dell’Europa centrale)', '').replace('GMT+0200 (Ora legale dell’Europa centrale)', '')
    else return value.toFixed(2)
}



function CircularProgressWithLabel(props) {
    return (
        <Stack direction='column' m={'1em'} alignItems='center' spacing={1}>

            <Typography variant="body2" component="div" color="text.secondary">
                <b>{props.title}</b>
            </Typography>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" value={100} color={props.label < 2 ? 'success' : props.label < 10 ? 'warning' : 'error'}

                    size={100}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h4" component="div" color="text.secondary">
                        {props.label < 0.01 ? 0 : `${props.label.toFixed(2)}`}
                    </Typography>
                </Box>
            </Box>
        </Stack>
    );
}


function getStatusColor(queueTime) {

    if (queueTime > 10) return 'error';
    else if (queueTime > 2) return 'warning';
    else return 'success'
}