import React, { useState, useEffect } from 'react';
import { Box, Autocomplete, TextField, Typography, List, Stack, Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import HelpIcon from '@mui/icons-material/Help';

import Primarytheme from './theme';

const axios = require('axios');

export default function AccountInfo({ auth }) {

    // state project
    const [attachedProjects, setAttachedProjects] = useState(auth.userState.attached_projects);
    const [selectedProject, setSelectedProject] = useState(undefined);
    const [selectedPrefix, setSelectedPrefix] = useState({ prefix: undefined, space: undefined, maxSpace: undefined, exclude_folders_gb_limit_total: undefined });
    const [selectedAssetPrefix, setSelectedAssetPrefix] = useState({ prefix: undefined, space: undefined, maxAssetSpace: undefined, exclude_folders_gb_asset_limit: undefined });
    const [getRowLimit, setRowLimit] = useState(undefined);
    const [getImageLimit, setImageLimit] = useState(undefined);
    const [conf, setConf] = useState({});

    // fecth projects when open dialog
    useEffect(() => {
        if (auth.userState.userItem) {
            setAttachedProjects(auth.userState.attached_projects)
            if (attachedProjects.length === 1) {
                handleChangeProject(undefined, attachedProjects[0])
            }
        }
    }, []);

    const handleChangeProject = (event, value) => {
        if (!value) {
            // rest all states
            setSelectedPrefix({ prefix: undefined, space: undefined, maxSpace: undefined, exclude_folders_gb_limit_total: undefined });
            setSelectedAssetPrefix({ prefix: undefined, space: undefined, maxAssetSpace: undefined, exclude_folders_gb_asset_limit: undefined });
            setSelectedProject(undefined);
            setImageLimit(undefined);
            setRowLimit(undefined);
            setConf({});
        }
        else {
            setSelectedProject(value);
            setSelectedPrefix({ prefix: `documents.pagination.com/${value}`, space: 'in-progress', maxSpace: undefined, exclude_folders_gb_limit_total: undefined });
            setSelectedAssetPrefix({ prefix: `documents.pagination.com/${value}`, space: 'in-progress', maxAssetSpace: undefined, exclude_folders_gb_asset_limit: undefined });

            setRowLimit(undefined);
            setImageLimit(undefined);

            // fetch conf
            fetchConf({ project_id: value })
                .then(response => {
                    const projectConf = response.data.projectConf;
                    setConf(projectConf);

                    const bucket = projectConf.bucket ? projectConf.bucket : 'documents.pagination.com';
                    const maxSpace = (projectConf.gb_limit_total && projectConf.gb_limit_total !== 'no limit') ? projectConf.gb_limit_total : undefined;
                    const maxAssetSpace = (projectConf.gb_asset_limit && projectConf.gb_asset_limit !== 'no limit') ? projectConf.gb_asset_limit : undefined;
                    const imageLimit = (projectConf.image_limit && projectConf.image_limit !== 'no limit') ? projectConf.image_limit : undefined;
                    const rowLimit = (projectConf.row_limit && projectConf.row_limit !== 'no limit') ? projectConf.row_limit : undefined;

                    const exclude_folders_gb_limit_total = (projectConf.exclude_folders_gb_limit_total && projectConf.exclude_folders_gb_limit_total.length) ? projectConf.exclude_folders_gb_limit_total : undefined;
                    const exclude_folders_gb_asset_limit = (projectConf.exclude_folders_gb_asset_limit && projectConf.exclude_folders_gb_asset_limit.length) ? projectConf.exclude_folders_gb_asset_limit : undefined;

                    getPrefixSpace({ prefix: `${bucket}/${value}`, maxSpace, maxAssetSpace, imageLimit, rowLimit, exclude_folders_gb_limit_total, exclude_folders_gb_asset_limit })
                        .then(responseSpace => {
                            console.log('calculate-space-response', responseSpace);
                        })

                });

        }
    };

    const getPrefixSpace = ({ prefix, maxSpace, maxAssetSpace, imageLimit, rowLimit, exclude_folders_gb_limit_total, exclude_folders_gb_asset_limit }) => {
        return axios({
            method: "get",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': auth.userState.user,
                "content-type": "application/json",
                "x-api-key": auth.userState.ssmParams.X_API_KEY,
                "prefix": prefix,
                "exclude_folders_gb_limit_total": exclude_folders_gb_limit_total,
                "exclude_folders_gb_asset_limit": exclude_folders_gb_asset_limit,
            },
        }).then(response => {
            setSelectedPrefix({ prefix, space: response.data.spaceFolder, maxSpace, exclude_folders_gb_limit_total });
            setSelectedAssetPrefix({ prefix, space: response.data.spaceAssetFolder, maxAssetSpace, exclude_folders_gb_asset_limit })
            // update state
            setImageLimit(imageLimit);
            setRowLimit(rowLimit);

            return 'calculate-sapce-done'
        }).catch(err => {
            console.log('calculate-space-error', err);
            setSelectedPrefix({ prefix, space: 'More than 50', maxSpace, exclude_folders_gb_limit_total });
            // update state
            setImageLimit(imageLimit);
            setRowLimit(rowLimit);
            return 'too many files'
        });
    }

    const fetchConf = ({ project_id }) => {
        const [customer, project_name] = project_id.split('/').slice(0, 2);
        //console.log(auth.userState.email)
        return axios.get('https://api.pagination.com/vpc/projects-webapp',
            {
                headers: {
                    'user_id': auth.userState.user,
                    "content-type": "application/json",
                    "x-api-key": auth.userState.ssmParams.X_API_KEY,
                    "project_id": project_id,
                    "project_name": project_name,
                    "customer": customer,
                }

            },
        );
    }


    return (
        <Box
            sx={{ width: '600px', minHeight: '200px', height: 'auto', margin: '1em' }}
        >
            <div>
                <Stack spacing={2} direction='column'>
                    <Typography variant='h5'
                        color={Primarytheme.palette.primary.main}
                        sx={{ marginBottom: '0.5em' }}>
                        <b>Project Info</b>
                    </Typography>
                    <Autocomplete
                        id="project-selector"
                        options={attachedProjects}
                        sx={{ width: '100%', maxWidth: 600 }}
                        clearOnEscape
                        defaultValue={attachedProjects.length === 1 ? attachedProjects[0] : ''}
                        value={selectedProject}
                        onChange={handleChangeProject}
                        size='small'
                        renderInput={(params) => <TextField {...params} label="project" />}
                    />
                    {selectedPrefix.space && selectedPrefix.space === 'in-progress' && (
                        <LinearProgress />
                    )}
                    <Stack justifyContent="space-evenly" spacing={4} direction='row'>
                        {selectedPrefix.space && selectedPrefix.space !== 'in-progress' && (
                            <List sx={{ align: 'center', width: '100%' }}>
                                <Typography sx={{ marginBottom: '0.5em' }} align='center'>
                                    Total Used space:
                                    <Tooltip title={
                                        <Typography variant='caption' >
                                            Space used for assets, data and output.
                                        </Typography>}>
                                        <HelpIcon sx={{ fontSize: '0.80em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                    </Tooltip>
                                </Typography>
                                <Typography align='center'>
                                    <b>{selectedPrefix.space} {selectedPrefix.maxSpace ? `/ ${selectedPrefix.maxSpace} ` : ''}GB</b>
                                </Typography>
                                {selectedPrefix.maxSpace && (
                                    <Box sx={{ marginTop: '1em', display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ width: '100%' }}>
                                            {Number(selectedPrefix.space) >= Number(selectedPrefix.maxSpace) && // add red line
                                                <LinearProgress variant="determinate" sx={{ height: 10 }} color='error' value={100} />
                                            }
                                            {Number(selectedPrefix.space) < Number(selectedPrefix.maxSpace) &&
                                                <LinearProgress variant="determinate" sx={{ height: 10 }} value={(Number(selectedPrefix.space) / Number(selectedPrefix.maxSpace)) * 100} />
                                            }
                                        </Box>
                                        {/* <Box sx={{ minWidth: 35 }}>
                                            <Typography variant="body2" color="text.secondary">{`${Math.round((selectedPrefix.space / selectedPrefix.maxSpace) * 100)} %`}</Typography>
                                        </Box> */}
                                    </Box>
                                )}
                            </List>
                        )}

                        {selectedAssetPrefix.maxAssetSpace !== 'no limits' && selectedAssetPrefix.space && Number(selectedAssetPrefix.space) !== 0 && selectedAssetPrefix.space !== 'in-progress' && (
                            <List sx={{ align: 'center', width: '100%' }}>
                                <Typography sx={{ marginBottom: '0.5em' }} align='center'>
                                    Asset Used space:
                                    <Tooltip title={
                                        <Typography variant='caption' >
                                            Space used for assets.
                                        </Typography>}>
                                        <HelpIcon sx={{ fontSize: '0.80em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                    </Tooltip>
                                </Typography>
                                <Typography align='center'>
                                    <b>{selectedAssetPrefix.space} {selectedAssetPrefix.maxAssetSpace ? `/ ${selectedAssetPrefix.maxAssetSpace} ` : ''}GB</b>
                                </Typography>
                                {selectedAssetPrefix.maxAssetSpace && (
                                    <Box sx={{ marginTop: '1em', display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ width: '100%' }}>
                                            {Number(selectedAssetPrefix.space) >= Number(selectedAssetPrefix.maxAssetSpace) && // add red line
                                                <LinearProgress variant="determinate" sx={{ height: 10 }} color='error' value={100} />
                                            }
                                            {Number(selectedAssetPrefix.space) < Number(selectedAssetPrefix.maxAssetSpace) &&
                                                <LinearProgress variant="determinate" sx={{ height: 10 }} value={(Number(selectedAssetPrefix.space) / Number(selectedAssetPrefix.maxAssetSpace)) * 100} />
                                            }
                                            {isNaN(selectedAssetPrefix.space) &&
                                                <LinearProgress variant="determinate" sx={{ height: 10 }} value={0} />
                                            }
                                        </Box>
                                        {/* <Box sx={{ minWidth: 35 }}>
                                            <Typography variant="body2" color="text.secondary">{`${Math.round((selectedAssetPrefix.space / selectedAssetPrefix.maxAssetSpace) * 100)} %`}</Typography>
                                        </Box> */}
                                    </Box>
                                )}
                            </List>
                        )}

                        {getRowLimit && (
                            <List sx={{ width: '100%' }}>
                                <Typography sx={{ marginBottom: '0.5em' }} align='center'>
                                    Row limit:
                                    <Tooltip title={
                                        <Typography variant='caption' >
                                            Maximum number of rows processed for each requests.
                                        </Typography>}>
                                        <HelpIcon sx={{ fontSize: '0.80em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                    </Tooltip>
                                </Typography>
                                <Typography align='center'>
                                    <b>{getRowLimit}</b>
                                </Typography>
                            </List>
                        )}

                        {getImageLimit && (
                            <List sx={{ width: '100%' }}>
                                <Typography sx={{ marginBottom: '0.5em' }} align='center'>
                                    Image limit:
                                    <Tooltip title={
                                        <Typography variant='caption' >
                                            Maximum number of images processed for each requests.
                                        </Typography>}>
                                        <HelpIcon sx={{ fontSize: '0.80em', ml: '0.5em', color: Primarytheme.palette.neutral.main }} />
                                    </Tooltip>
                                </Typography>
                                <Typography align='center'>
                                    <b>{getImageLimit}</b>
                                </Typography>
                            </List>
                        )}
                    </Stack>
                </Stack>
            </div>
        </Box>
    )

}
