import React, { useState, useEffect } from 'react';

import Primarytheme from './utility/theme';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CloseIcon from '@mui/icons-material/Close';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Snackbar } from '@mui/material';

import { styled } from '@mui/material/styles';

import { Grid, Typography, Stack, Checkbox, FormControlLabel, FormGroup, Button } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ProjectParameters from './utility/ProjectParameters';
import ProjectDisable from './utility/ProjectDisable';

const { buildUsernameFromEmail } = require('./auth/utils-auth');

const axios = require('axios');
var generator = require('generate-password');

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: Primarytheme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0px 0px 0px 0px, 1px 1px 5px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    border: '1px',
    minWidth: 950
}));


const {
    REACT_APP_X_API_KEY,
    REACT_APP_USER_ID,
} = process.env;




export default function Administration({ auth }) {

    const [projects, setProjects] = useState({ list: [], retrieving: true, });


    const [userMngmt, setUserMngmt] = useState({
        email: '',
        customer: '',
        isAdmin: false,
        type: '',
        policy: {
            canDelete: true,
            canLaunch: true,
            canManageAsset: true,
            filterUser: false,
        },
        //attached_project_ids: [],
        isCreating: false,
        created: false,
        selectedList: []
    })

    const [patchUsrMngmt, setPatchUsrMngmt] = useState({

        email: '',
        customer: '',
        attached_projects: [],
        selectedList: [],
        isAdmin: false,
        type: '',
        policy: {
            canDelete: false,
            canLaunch: false,
            canManageAsset: false,
            filterUser: false,
        },
        registrationDate: undefined,
        lastLogin: undefined,
        isFetching: false,
        fetched: false,
        isPatching: false,
        patched: false,
        openDelete: false,
    })

    const [customerFilter, setCustomerFilter] = useState({
        customer: auth.userState.isAdmin ? 'pagination' : '', // filtro qui di default a pagination, ma anche nell'autocomplete è default a quel valore
        isFetching: false,
        fetched: false,
        users: []
    })

    const [snack, setSnack] = useState({
        open: false,
        creationMessage: ''
    })

    const handleClickOpen = () => {
        setPatchUsrMngmt({ ...patchUsrMngmt, openDelete: true });
    };

    const handleAgree = (action) => {
        setPatchUsrMngmt({ ...patchUsrMngmt, openDelete: false });
        patchUser(action)
    };
    const handleDisagree = () => {
        setPatchUsrMngmt({ ...patchUsrMngmt, openDelete: false });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            open: false,
            creationMessage: ''
        });
    };

    const handleCustomerFilter = (event, value, reason) => {
        if (reason === 'clear') handleResetCustomerPatch();
        else {
            setCustomerFilter({ ...customerFilter, customer: value, users: [] })
            handleResetUserPatch()
        }
    }
    const handleSelectPathEmail = (event, value, reason) => {
        if (reason === 'clear') handleResetUserPatch()
        else {
            setPatchUsrMngmt({
                ...patchUsrMngmt, email: value.email, retrieving: true
            })
            getUserInfo(value.email)
                .then(response => {
                    //console.log(response.data);
                    const userData = response.data;
                    setPatchUsrMngmt({
                        ...patchUsrMngmt,
                        retrieving: false,
                        email: value.email,
                        selectedList: userData.attached_projects,
                        fetched: true,
                        attached_projects: userData.attached_projects,
                        isAdmin: userData.type === 'administrator',
                        type: userData.type,
                        policy: {
                            canDelete: userData.policy.hasOwnProperty('canDelete') ? userData.policy.canDelete : true,
                            canLaunch: userData.policy.hasOwnProperty('canLaunch') ? userData.policy.canLaunch : true,
                            canManageAsset: userData.policy.hasOwnProperty('canManageAsset') ? userData.policy.canManageAsset : true,
                            filterUser: userData.policy.hasOwnProperty('filterUser') ? userData.policy.filterUser : false
                        },
                        registrationDate: userData.registrationDate ? (new Date(userData.registrationDate)).toUTCString() : 'unknown',
                        lastLogin: userData.lastLogin ? (new Date(userData.lastLogin)).toUTCString() : 'unknown',
                    });
                    setProjects({ ...projects, retrieving: false })
                    //console.log(userData)
                    return userData
                })
                .catch(err => {
                    console.log(err)
                    setSnack({ open: true, creationMessage: 'fetch user error. Check dynamo.' })
                    setPatchUsrMngmt({ ...patchUsrMngmt, fetched: false });
                })
        }
    }
    const handleProjectSelectRegister = (event, values) => {
        setUserMngmt({ ...userMngmt, selectedList: values });
    }
    const handleProjectSelectPatch = (event, values) => {
        setPatchUsrMngmt({ ...patchUsrMngmt, selectedList: values });
    }

    const handleResetUserPatch = () => {
        setPatchUsrMngmt({
            email: '',
            attached_projects: [],
            selectedList: [],
            isAdmin: false,
            type: '',
            registrationDate: undefined,
            lastLogin: undefined,
            policy: {
                canDelete: false,
                canLaunch: false,
                canManageAsset: false,
                filterUser: false,
            },
            isFetching: false,
            fetched: false,
            isPatching: false,
            patched: false,
            openDelete: false,
        })
    }
    const handleResetCustomerPatch = () => {
        setCustomerFilter({
            customer: '',
            isFetching: false,
            fetched: false,
            users: []
        })
        handleResetUserPatch()
    }

    const fetchCustomerUsers = async () => {
        setCustomerFilter({ ...customerFilter, isFetching: true });
        return axios.get(`https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/users`,
            {
                headers: {
                    'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,

                    "customer": customerFilter.customer,
                    "action": 'fetch-customer'
                }

            })
            .then(response => {
                const users = response.data.users;
                setCustomerFilter({
                    ...customerFilter,
                    //selectedList: userData.attached_projects,
                    isFetching: false,
                    fetched: true,
                    users: users,

                });
                return users
            })
            .catch(err => {
                console.log(err)
                setSnack({ open: true, creationMessage: 'fetch user error. Check dynamo.' })
                setCustomerFilter({ ...customerFilter, fetched: false, isFetching: false });
            })

    }
    const retrieveAllProjects = async () => {
        axios.get('https://api.pagination.com/vpc/projects-webapp',
            {
                headers: {
                    'user_id': auth.userState.user,
                    "content-type": "application/json",
                    "x-api-key": auth.userState.ssmParams.X_API_KEY,
                    "type": 'project-list',
                }
            },
        )
            .then(response => {
                //console.log(response.data.projectsList.map(el => `${el.customer}/${el.project_name}`))
                setProjects({ ...projects, list: response.data.projectsList.map(el => `${el.customer}/${el.project_name}`), retrieving: false });
            })
            .catch(err => {
                console.log(err)
                setProjects({ ...projects, retrieving: false });
            })
    }

    const getUserInfo = (email) => {
        return axios.get(`https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/users`,
            {
                headers: {
                    'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                    "userInfo_id": buildUsernameFromEmail(email), // lo user id è l'email con la chiocciola sostituita dal punto. per i primi utenti è solo la parte prima della chiocchiola
                    "email": email
                }

            })
    }



    // register and patch
    const registerNewUser = async () => {

        setUserMngmt({ ...userMngmt, isCreating: true });

        console.log('creatin user init', userMngmt)
        const password = generator.generate({
            length: 10,
            numbers: true,
            symbols: false,
            lowercase: true,
            uppercase: true,
            strict: true
        }).toString();


        axios.post(`https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/register`,
            {
                user_id: buildUsernameFromEmail(userMngmt.email), //lo user id è sempre minuscolo, con punto al posto della chiocchiola, chiave dynamo
                email: userMngmt.email,
                customer: userMngmt.customer,
                isAdmin: userMngmt.isAdmin,
                policy: userMngmt.policy,
                type: userMngmt.type,
                password: password,
                attached_projects: userMngmt.selectedList,
            },
            {
                headers: {
                    'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                }

            })
            .then(response => {
                console.log(response.data);
                setSnack({ open: true, creationMessage: JSON.stringify(response.data) })
                setUserMngmt({ ...userMngmt, isCreating: false, created: true });
            })
            .catch(err => {
                console.log(err)
                setSnack({ open: true, creationMessage: JSON.stringify(err) })
                setUserMngmt({ ...userMngmt, isCreating: false });
            })
            .finally(() => {
                setUserMngmt({
                    ...userMngmt,
                    email: '',
                    isAdmin: false,
                    type: '',
                    policy: { canDelete: true, canLaunch: true, canManageAsset: true, filterUser: false },
                    isCreating: false,
                    created: false,
                })
            })


    }

    const patchUser = async (action = 'patch') => {
        setPatchUsrMngmt({ ...patchUsrMngmt, isPatching: true });

        axios.patch(`https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/users`,
            {
                userInfo_id: buildUsernameFromEmail(patchUsrMngmt.email),
                project_ids: patchUsrMngmt.selectedList,
                policy: patchUsrMngmt.policy,
                type: patchUsrMngmt.type,
                action,
                user_pool_id: auth.userState.ssmParams.USER_POOL_ID
            },
            {
                headers: {
                    'user_id': REACT_APP_USER_ID || auth.userState.ssmParams.USER_ID,
                    "content-type": "application/json",
                    "x-api-key": REACT_APP_X_API_KEY || auth.userState.ssmParams.X_API_KEY,
                }

            })
            .then(() => {

                if (patchUsrMngmt.email === auth.userState.email) {
                    auth.setUserState({ ...auth.userState, attached_projects: patchUsrMngmt.selectedList.sort() })
                }
                setSnack({ open: true, creationMessage: patchUsrMngmt.selectedList.join(', ') })
            })
            .catch(err => {
                console.log(err)
                setSnack({ open: true, creationMessage: JSON.stringify(err) })
            })
            .finally(() => {
                handleResetUserPatch()
            })
    }


    // refresh al montaggio
    useEffect(() => {
        retrieveAllProjects();
    }, []);


    return (
        <div>
            <Grid container spacing={2} sx={{
                marginTop: '7em',
                marginBottom: '2em',
                paddingLeft: '32px',
                paddingRight: '32px',
                flexGrow: 1,

            }}>
                <Grid item xs={12} md={12} >
                    <Item key='add-user'>
                        <Typography variant='h5' sx={{ marginBottom: '1em' }} >
                            <b>Register user</b>
                        </Typography>
                        <Stack direction='row' spacing={2} >
                            <TextField key='email'
                                label='email'
                                helperText='If the user is admin he can be attached to any project'
                                value={userMngmt.email}
                                size='small'
                                sx={{ width: '30%' }}
                                onChange={(event) => setUserMngmt({
                                    ...userMngmt,
                                    email: event.target.value
                                })}
                            />
                            <Autocomplete
                                disabled={projects.list.length === 0}
                                limitTags={1}
                                options={Array.from(new Set(projects.list.map(p => p.split('/').shift()))).sort()}
                                onChange={(event, value) => setUserMngmt({ ...userMngmt, customer: value })}
                                sx={{ width: '20%' }}
                                size='small'
                                renderInput={(params) => <TextField {...params} variant="outlined" label={projects.retrieving ? 'loading...' : 'customers'} placeholder="" />}
                            />
                            <Autocomplete
                                multiple
                                disabled={projects.list.length === 0}
                                limitTags={5}
                                disableCloseOnSelect
                                id="multiple-limit-tags"
                                options={projects.list.filter(p => userMngmt.isAdmin || p.split('/').shift() === userMngmt.customer)}
                                onChange={handleProjectSelectRegister}
                                sx={{ width: '50%' }}
                                size='small'
                                getOptionLabel={(option) => option}
                                //defaultValue={[]}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={projects.retrieving ? 'loading...' : 'project-ids'} placeholder="" />
                                )}

                            />

                        </Stack>


                        <Box>
                            <FormGroup>
                                <Autocomplete
                                    disabled={!userMngmt.email}
                                    limitTags={1}
                                    options={['administrator', 'billing', 'customer']}
                                    defaultValue={'customer'}
                                    value={userMngmt.type ? userMngmt.type : 'customer'}
                                    onChange={(event, value, reason) => setUserMngmt({
                                        ...userMngmt,
                                        isAdmin: value === 'administrator',
                                        type: value
                                    })}
                                    sx={{ width: '20%', mb: '1em', mt: '1em' }}
                                    size='small'
                                    renderInput={(params) => <TextField {...params} variant="outlined" label={'User Type'} placeholder="" />}
                                />
                                <Typography variant='body2' color={'primary'}>
                                    <b>User policy:</b>
                                </Typography>
                                <Stack direction='row' spacing={2} id='admin-check'>
                                    {Object.keys(userMngmt.policy).map(pol => (
                                        <FormControlLabel key={pol + 'r'} control={
                                            <Checkbox
                                                component='div'
                                                key='checkbox'
                                                label={pol}
                                                size='small'
                                                checked={userMngmt.policy[pol]}
                                                disabled={!userMngmt.email}
                                                onChange={(event) => setUserMngmt({
                                                    ...userMngmt,
                                                    policy: {
                                                        ...userMngmt.policy,
                                                        [pol]: event.target.checked
                                                    }
                                                })}
                                            />}
                                            label={pol} />
                                    ))}
                                </Stack>
                            </FormGroup>
                        </Box>

                        <LoadingButton
                            disabled={userMngmt.email === '' || userMngmt.selectedList.length === 0}
                            loading={userMngmt.isCreating}
                            loadingPosition="start"
                            startIcon={<PersonAddAltIcon />}
                            variant="contained"

                            onClick={registerNewUser}
                            sx={{ marginTop: '1em' }}
                        >
                            Register
                        </LoadingButton>


                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={snack.open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message={snack.creationMessage}
                            action={
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            }

                        />

                    </Item>

                </Grid>


                <Grid item xs={12} md={12}>
                    <Item key='patch-user' >
                        <Typography variant='h5' sx={{ marginBottom: '1em' }} >
                            <b>Info / Patch user</b>
                        </Typography>
                        <Stack direction='row' spacing={2} alignItems='flex-start' sx={{ width: '100%' }}>
                            <Autocomplete
                                disabled={projects.list.length === 0}
                                limitTags={1}
                                options={Array.from(new Set(projects.list.map(p => p.split('/').shift()))).sort()}
                                onChange={handleCustomerFilter}
                                defaultValue={auth.userState.isAdmin ? 'pagination' : undefined}
                                sx={{ width: '13em', minWidth: '13em' }}
                                size='small'
                                renderInput={(params) => <TextField {...params} variant="outlined" label={projects.retrieving ? 'loading...' : 'customers'} placeholder="" />}
                            />


                            <LoadingButton
                                disabled={customerFilter.customer === ''}
                                loading={customerFilter.isFetching}
                                variant="contained"
                                onClick={fetchCustomerUsers}
                                sx={{ width: '10em', minWidth: '10em', }}
                            >
                                fetch users
                            </LoadingButton>


                            <Autocomplete
                                disabled={customerFilter.users.length === 0}
                                limitTags={1}
                                options={customerFilter.users}
                                getOptionLabel={(option) => option.email}
                                isOptionEqualToValue={(option, value) => option.email === value.email}
                                defaultValue={{ email: '' }}
                                value={{ email: patchUsrMngmt.email }}
                                onChange={handleSelectPathEmail}
                                sx={{ width: '50%', minWidth: '15em' }}
                                size='small'
                                renderInput={(params) => <TextField {...params} variant="outlined" label={customerFilter.isFetching ? 'loading...' : 'users'} placeholder="" />}
                            />


                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                disabled={projects.list.length === 0 || patchUsrMngmt.email === ''}
                                limitTags={5}
                                id="multiple-limit-tags"
                                options={projects.list}
                                value={patchUsrMngmt.selectedList}
                                onChange={handleProjectSelectPatch}
                                sx={{ width: '50%' }}
                                size='small'
                                getOptionLabel={(option) => option}
                                defaultValue={patchUsrMngmt.attached_projects}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={patchUsrMngmt.retrieving ? 'loading...' : 'project-ids'} placeholder="" />
                                )}
                            />


                        </Stack>
                        <Box mt='1em' mb='0.5em'>
                            <Typography variant='body2' color={patchUsrMngmt.registrationDate ? 'primary' : 'lightGray'}>
                                <b>Registration date:</b> {patchUsrMngmt.registrationDate ? patchUsrMngmt.registrationDate : '...'}
                            </Typography>
                            <Typography variant='body2' color={patchUsrMngmt.lastLogin ? 'primary' : 'lightGray'}>
                                <b>Last login date:</b> {patchUsrMngmt.lastLogin ? patchUsrMngmt.lastLogin : '...'}
                            </Typography>
                        </Box>
                        <Box id='admin-check'>
                            <FormGroup>
                                <Autocomplete
                                    disabled={!patchUsrMngmt.fetched}
                                    limitTags={1}
                                    options={['administrator', 'billing', 'customer']}
                                    defaultValue={'customer'}
                                    value={patchUsrMngmt.type ? patchUsrMngmt.type : 'customer'}
                                    onChange={(event, value, reason) => setPatchUsrMngmt({
                                        ...patchUsrMngmt,
                                        isAdmin: value === 'administrator',
                                        type: value
                                    })}
                                    sx={{ width: '20%', mb: '1em', mt: '1em' }}
                                    size='small'
                                    renderInput={(params) => <TextField {...params} variant="outlined" label={'User Type'} placeholder="" />}
                                />
                                <Typography variant='body2' color={'primary'}>
                                    <b>User policy:</b>
                                </Typography>
                                <Stack direction='row' spacing={2} id='admin-check'>
                                    {Object.keys(patchUsrMngmt.policy).map(pol => (
                                        <FormControlLabel key={pol} control={
                                            <Checkbox
                                                component='div'
                                                key='checkbox'
                                                label={pol}
                                                disabled={!patchUsrMngmt.fetched}
                                                size='small'
                                                checked={patchUsrMngmt.policy[pol]}
                                                onChange={(event) => setPatchUsrMngmt({
                                                    ...patchUsrMngmt,
                                                    policy: {
                                                        ...patchUsrMngmt.policy,
                                                        [pol]: event.target.checked
                                                    }
                                                })}
                                            />}
                                            label={pol} />
                                    ))}
                                </Stack>
                            </FormGroup>
                        </Box>
                        <Stack direction='row' spacing={2} mt='1em'>
                            <LoadingButton
                                disabled={patchUsrMngmt.email === '' || patchUsrMngmt.selectedList.length === 0}
                                loading={patchUsrMngmt.isPatching}
                                loadingPosition="start"
                                startIcon={<PersonAddAltIcon />}
                                variant="contained"
                                onClick={() => patchUser('patch')}
                            >
                                Patch
                            </LoadingButton>
                            <LoadingButton
                                disabled={patchUsrMngmt.email === '' || patchUsrMngmt.selectedList.length === 0}
                                loading={patchUsrMngmt.isPatching}
                                loadingPosition="start"
                                startIcon={<PersonRemoveIcon />}
                                variant="contained"
                                color='error'
                                onClick={handleClickOpen}
                            >
                                Delete
                            </LoadingButton>
                            <Dialog
                                open={patchUsrMngmt.openDelete}
                                onClose={handleDisagree}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Delete user</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure to delete {patchUsrMngmt.email}?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleDisagree} color="primary">
                                        Disagree
                                    </Button>
                                    <Button onClick={() => handleAgree('delete')} color="primary" autoFocus>
                                        Agree
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </Stack>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={snack.open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message={snack.creationMessage}
                            action={
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            }

                        />

                    </Item>
                </Grid>


                <Grid item xs={12} md={12}>
                    <Item key='project-parameters' >
                        <Typography variant='h5' sx={{ marginBottom: '1em' }} >
                            <b>Project Parameters</b>
                        </Typography>
                        <ProjectParameters auth={auth} projects={projects} />
                    </Item>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Item key='project-parameters' >
                        <Typography variant='h5' sx={{ marginBottom: '1em' }} >
                            <b>Project Management</b>
                        </Typography>
                        <ProjectDisable auth={auth} projects={projects} />
                    </Item>
                </Grid>
            </Grid>
        </div >
    )


}







