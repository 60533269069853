import Box from '@mui/material/Box';
import { useState, useRef, useEffect } from 'react';

import Primarytheme from './theme';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Chip from '@mui/material/Chip';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Refresh from '@mui/icons-material/Refresh';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import HelpIcon from '@mui/icons-material/Help';

import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    GridOverlay
} from '@mui/x-data-grid';

import Pagination from '@mui/material/Pagination';

import AttentionBlock from './AttentionBlock';
import AlertDialog from './AlertDialog';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import AssetEditTags from './AssetEditTags';
import AssetUpdateIndd from './AssetUpdateIndd';


const axios = require('axios');

const { v4: uuidv4 } = require('uuid');

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.15),
    marginLeft: 0, marginRight: theme.spacing(0.4),
}));

const sortWeightObj = {
    'idml': 0,
    'links': 1,
    'fonts': 2,
}

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    if (pageCount > 1) return (
        <Pagination
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}

        />
    )
    else return null
}

function CustomNoRowsOverlay(tableName) {
    return (
        <GridOverlay>
            <div>No item in {tableName}</div>
        </GridOverlay>
    )
}


const PdfPreview = ({ pdfName, focused, width }) => {


    return (
        <Box key={pdfName}
            mt='0.5em' sx={{ width: focused ? 300 : width * 0.76, }}>
            {pdfName && (
                <Document
                    key={pdfName}
                    file={pdfName}
                    //onLoadSuccess={onDocumentLoadSuccess}
                    loading={< LinearProgress
                        key={pdfName}
                        sx={{ color: 'primary' }}
                        variant="indeterminate"
                    />}
                // onLoadError={() => <p>nessun pdf</p>}
                //onLoadProgress={({ loaded, total }) => alert('Loading a document: ' + (loaded / total) * 100 + '%')}
                >
                    <Stack direction='row' justifyContent="flex-start">
                        <Page width={focused ? 300 : width * 0.76}
                            height={focused ? 300 : width}
                            pageNumber={1}
                            renderTextLayer={false} renderAnnotationLayer={false} loading={null} error={() => null} />
                    </Stack>
                </Document>
            )}
        </Box>
    )
}



export default function AssetSingleIndesignBlock({ document, auth, tagsKeys, handleClickFilterChip, handleRefreshTable, folderPath, folderName, tagOrderedObj, format, bucket, project_id, checkLinks }) {

    const cardElement = useRef();

    const [focused, setFocused] = useState({ focused: false, width: 250 });

    const [links, setLinks] = useState({
        missingLiks: document.linksCheckArr.map(el => decodeURIComponent(el)),
        unusedLinks: document.unusedLinksArr.map(el => decodeURIComponent(el)),
    })

    const [openEdit, setOpenEdit] = useState(false);

    const [openUpdate, setOpenUpdate] = useState(false);

    const handleClickOpenUpdate = () => {
        setOpenUpdate(true);
    };
    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    };

    const handleEditIndd = () => {
        setOpenEdit(true)
    }


    const handleInddSelect = () => {
        if (focused.focused) {
            setFocused({ focused: false, width: 250 });
        } else {
            setFocused({ focused: true, width: '100%' });
        }
    }

    const handleJumpS3 = () => {
        //https://s3.console.aws.amazon.com/s3/object/documents.pagination.com?region=eu-west-1&prefix=gardner-denver/price-list/indesign-GD/chapters/A_Cover_1/A_Cover_1.idml
        window.open(`https://s3.console.aws.amazon.com/s3/buckets/${document.indd.bucket}?region=eu-west-1&prefix=${document.indd.fullPath.split('###').pop().split('/').slice(0, -1).join('/').replace(/ /g, '+')}/`);
    }

    const deleteDocumentS3 = (docPath) => {
        console.log('delete indesign', docPath)

        const uri = 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets';
        // console.log(uri);
        return axios({
            method: "post",
            url: uri,
            headers: {
                'user_id': auth.userState.ssmParams.USER_ID,
                "content-type": "application/json",
                "x-api-key": auth.userState.ssmParams.X_API_KEY,
                "action": "delete-indesign"
            },
            data: {
                docPath
            }
        })
            .then(resp => {
                // console.log(resp.data);

                setLinks({
                    missingLiks: [],
                    unusedLinks: [],
                })

                // refresh!!
                handleRefreshTable()
            })
            .catch(err => {
                // console.log(err)
            });
    }

    const deleteFileS3 = async ({ tableKey, keyList, docPath }) => {
        let dynamoArrRequest = [];
        for (let i = 0; i < keyList.length; i++) {
            dynamoArrRequest.push(keyList[i]);

            if (i % 25 === 0 || i === keyList.length - 1) {
                const delReq = await axios({
                    method: "post",
                    url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
                    headers: {
                        'user_id': auth.userState.ssmParams.USER_ID,
                        "content-type": "application/json",
                        "x-api-key": auth.userState.ssmParams.X_API_KEY,
                        "action": "delete-batch"
                    },
                    data: { keyList: dynamoArrRequest }
                })
                console.log('resp delete:', i, delReq)

                dynamoArrRequest = [];
            }

        }

        if (checkLinks !== 'false' && checkLinks !== false) {
            const checkDone = checkIdmlFiles(docPath)
        }
    }

    const checkIdmlFiles = (docPath) => {
        return axios({
            method: "post",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': auth.userState.ssmParams.USER_ID,
                "content-type": "application/json",
                "x-api-key": auth.userState.ssmParams.X_API_KEY,
                "src": docPath,
                "project_id": project_id,
                "action": 'dynamo-checks'
            },
        })
            .then(resp => {
                const linksObj = {
                    missingLiks: resp.data.linksCheckArr.map(el => decodeURIComponent(el)),
                    unusedLinks: resp.data.unusedLinksArr.map(el => decodeURIComponent(el)),
                }
                setLinks(linksObj);

                // refresh!!
                handleRefreshTable();

                document.linksCheckArr = resp.data.linksCheckArr.map(el => decodeURIComponent(el));
                document.unusedLinksArr = resp.data.unusedLinksArr.map(el => decodeURIComponent(el));

                return resp;
            })
            .catch(err => {
                // console.log(err);
                return err;
            })
    }

    // update project list
    useEffect(() => {
        setLinks({
            missingLiks: document.linksCheckArr.map(el => decodeURIComponent(el)),
            unusedLinks: document.unusedLinksArr.map(el => decodeURIComponent(el)),
        })
    }, [document]);

    useEffect(() => {
        if (cardElement.current) {
            cardElement.current.scrollIntoView({ block: "center" });
            //setTimeout(() => cardElement.current.scrollIntoView({ block: 'center' }), 77);
        }
    }, [focused]);

    return (

        <ListItem ref={cardElement} key={document.indd.Name + document.reqid}
            sx={{ width: focused.width, marginRight: '1em', marginTop: '1em' }}>
            <Card >

                <CardContent sx={{ '&:last-child': { paddingBottom: '0.5em' } }}>
                    <Stack direction='column' alignItems="flex-start" >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="start"

                            sx={{ width: '100%' }}
                        >
                            <Stack direction='column' spacing={2} mb='0.5em' sx={{ width: '80%' }} >
                                <Box sx={{ width: '100%' }}>
                                    <Typography sx={{ fontSize: focused.focused ? 18 : 13, wordWrap: 'break-word', margin: 0, mt: '0.5em' }} gutterBottom>
                                        <b>{document.filename}</b>
                                    </Typography>
                                    <Typography variant='caption' color='textSecondary' component='div' mt='0.5em'>
                                        {(new Date(Number(document.indd['uploadDate']))).toLocaleString().replace(/T/, ' ').replace(/\..+/, '')}
                                    </Typography>
                                    {auth.userState.isAdmin && focused.focused && (
                                        <Typography variant='caption' sx={{ fontSize: 10, wordWrap: 'break-word', }} gutterBottom>
                                            {document.reqid}
                                        </Typography>
                                    )}
                                </Box>
                                {!focused.focused && links.missingLiks && links.missingLiks.length > 0 && (
                                    <AttentionBlock
                                        color='warning'
                                        attentions={[{ name: '', header: ['Link Name'], data: links.missingLiks.map(el => [el]) }]}
                                        notes='Please upload the missing links'
                                        buttonLabel='Missing links'
                                        title={'Missing links ' + document.filename}
                                        size='small'
                                        textAlign='left'
                                    />

                                )}
                                <Box component='ul' sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0,
                                    m: 0,

                                }}>
                                    {tagsKeys.map(tag => (
                                        <ListItem key={document.reqid + tag + document.tags[tag]}>
                                            <Chip label={<Typography sx={{ fontSize: 13, whiteSpace: 'normal' }}>
                                                {document.tags[tag]}</Typography>}
                                                variant="outlined" color='primary' size="small" sx={{ height: '100%' }}
                                                onClick={() => handleClickFilterChip({ tagKey: tag, tagValue: document.tags[tag] })}
                                            />
                                        </ListItem>
                                    ))}
                                </Box>

                            </Stack>
                            <Stack direction='column' sx={{}} >

                                {focused.focused ?
                                    <Tooltip title="Collapse cover components">
                                        <IconButton onClick={handleInddSelect}>
                                            <ZoomOutIcon fontSize={focused.focused ? 'medium' : 'small'} />
                                        </IconButton>
                                    </Tooltip> :
                                    <Tooltip title="Explore cover components">
                                        <IconButton onClick={handleInddSelect}>
                                            <ZoomInIcon fontSize={focused.focused ? 'medium' : 'small'} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip title="Edit tags">
                                    <IconButton onClick={() => handleEditIndd()}>
                                        <EditIcon fontSize={focused.focused ? 'medium' : 'small'} />
                                    </IconButton>
                                </Tooltip>
                                <Box sx={{ textAlign: 'right', pr: '4px' }}>
                                    <AlertDialog title='Delete this document?'
                                        text="Deleting the document will delete the inDesign file along with the Links and the Document fonts associated with it."
                                        handleActionOnAgree={() => deleteDocumentS3(`${folderPath}/${document.reqid}`)} //!!!!!!!!!!!!!!!!!!!!!!!!!
                                        icon={<DeleteIcon fontSize={focused.focused ? 'medium' : 'small'} />}

                                        //buttonProps={{ label: 'delete', variant: 'outlined', size: 'small' }}
                                        tip='Delete document'

                                    />
                                </Box>
                                <Tooltip title="Replace idml and upload new Links/Fonts">
                                    <IconButton onClick={() => handleClickOpenUpdate()}>
                                        <PublishedWithChangesIcon fontSize={focused.focused ? 'medium' : 'small'} />
                                    </IconButton>
                                </Tooltip>
                                {openUpdate && <AssetUpdateIndd {...{ openUpdate, handleCloseUpdate, document, folderName, tagsKeys, tagOrderedObj, format, auth, bucket, project_id, folderPath, checkLinks, handleRefreshTable, setLinks }} />}
                                {auth.userState.isAdmin && (
                                    <Tooltip title="Jump to S3 web console">
                                        <IconButton onClick={handleJumpS3}>
                                            <OpenInNewIcon fontSize={focused.focused ? 'medium' : 'small'} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <AssetEditTags
                                    document={document}
                                    uploadOpen={openEdit}
                                    docPath={`${folderPath}/${document.reqid}`}
                                    tagsKeys={tagsKeys} auth={auth}
                                    handleUploadDialogClose={() => setOpenEdit(false)}
                                    handleRefreshTable={handleRefreshTable}
                                />

                            </Stack>
                        </Stack>

                        <Stack direction='row' spacing={6} mt='0.5em' pb='0.5em' pr={0} justifyContent='flex-start'>

                            {document.pdf && (
                                <PdfPreview key={document.indd.Name}
                                    pdfName={document.pdf}
                                    focused={focused.focused}
                                    width={focused.width}
                                />
                            )}
                            {!document.pdf && document && (
                                <img src={document.jpg} alt='' height={focused.focused ? '13%' : '100%'} width={focused.focused ? 200 : '100%'} />
                            )}
                            {focused.focused && (
                                <Box key='fonts-links-tables' component='ul'
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        flexWrap: 'wrap',
                                        listStyle: 'none',
                                        p: 0,
                                        m: 0,
                                    }}>

                                    {Object.keys(document).filter(k => k === 'fonts' || k === 'links' || k === 'idml').sort((a, b) => sortWeightObj[a] - sortWeightObj[b]).map(tableKey => (
                                        <ListItem key={document[tableKey].name} sx={{ width: 400, m: '1em' }}>
                                            <Stack direction='row' spacing={3} justifyContent="space-between" alignItems="center" mb="1em" sx={{ height: 40 }}>
                                                <Stack direction='row' justifyContent="space-between" alignItems="top">
                                                    <Typography sx={{ fontSize: 14, wordWrap: 'break-word' }} gutterBottom color='primary'>
                                                        <b>{document[tableKey].name}</b>
                                                    </Typography>
                                                    {tableKey === 'idml' && (
                                                        <Tooltip title={
                                                            <Typography variant='caption' >
                                                                To replace the Idml file use the icon <PublishedWithChangesIcon sx={{ fontSize: '1.2em' }} /> on the right
                                                            </Typography>}>
                                                            <HelpIcon sx={{ fontSize: '0.8em', ml: '0.5em', color: Primarytheme.palette.primary.main }} />
                                                        </Tooltip>
                                                    )}
                                                    {tableKey === 'links' && (
                                                        <Tooltip title={
                                                            <Typography variant='caption' >
                                                                To replace or add Links files use the icon <PublishedWithChangesIcon sx={{ fontSize: '1.2em' }} /> on the right
                                                            </Typography>}>
                                                            <HelpIcon sx={{ fontSize: '0.8em', ml: '0.5em', color: Primarytheme.palette.primary.main }} />
                                                        </Tooltip>
                                                    )}
                                                    {tableKey === 'fonts' && (
                                                        <Tooltip title={
                                                            <Typography variant='caption' >
                                                                To add fonts use the icon <PublishedWithChangesIcon sx={{ fontSize: '1.2em' }} /> on the right
                                                            </Typography>}>
                                                            <HelpIcon sx={{ fontSize: '0.8em', ml: '0.5em', color: Primarytheme.palette.primary.main }} />
                                                        </Tooltip>
                                                    )}
                                                </Stack>

                                                {tableKey === 'links' && links.unusedLinks.length !== 0 && (
                                                    <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center' mb="1em" sx={{ height: 40 }}>
                                                        <AlertDialog title='Delete unused links'
                                                            text={
                                                                <TableContainer component={Box} mt='0.5em' >
                                                                    <Table size="small" >
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {'Unused links'}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {links.unusedLinks.map(el => (
                                                                                <TableRow key={el} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                                                    <TableCell sx={{ border: 0 }} key={el + 'cell'} >{decodeURIComponent(el)}</TableCell >
                                                                                </TableRow>)
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            }
                                                            handleActionOnAgree={async () => await deleteFileS3({ tableKey, keyList: links.unusedLinks.map(el => `${document.bucket}###${document.keyName.replace('/' + document.filename, '')}/Links/${decodeURIComponent(el)}`), docPath: `${folderPath}/${document.reqid}` })}
                                                            icon={<Button // da passare gli unused links !!!!!!
                                                                variant='contained'
                                                                size='small'
                                                                startIcon={<DeleteIcon />}
                                                                disabled={links.unusedLinks.length === 0}
                                                            >
                                                                Delete unused links
                                                            </Button>}
                                                            tip='Clear process'
                                                        />
                                                        <IconButton onClick={handleRefreshTable} > <Refresh /> </IconButton>
                                                    </Stack>
                                                )}
                                            </Stack>
                                            <DataGrid
                                                key={document[tableKey].name}
                                                rows={document[tableKey].rows}
                                                columns={document[tableKey].columns}
                                                autoHeight
                                                density="compact"

                                                disableSelectionOnClick
                                                pageSizeOptions={[5]}

                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 10

                                                        }
                                                    },
                                                }}

                                                slots={{
                                                    pagination: CustomPagination,
                                                    noRowsOverlay: () => CustomNoRowsOverlay(document[tableKey].name),
                                                }}
                                                sx={{
                                                    '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
                                                    '& .MuiDataGrid-cell:focus': { outline: 'none' },
                                                    '& .MuiDataGrid-columnHeaderCheckbox:focus': { outline: 'none' },
                                                    '& .MuiDataGrid-cellCheckbox': { outline: 'none', color: 'red' },
                                                    '& .MuiDataGrid-footerContainer': { minHeight: 0 },
                                                }}
                                            />
                                            {tableKey === 'idml' && (
                                                <Stack direction='column' spacing={3} justifyContent="space-between" alignItems="center" mt="1em" sx={{ height: 40 }}>
                                                    {links.missingLiks && links.missingLiks.length > 0 && (
                                                        <AttentionBlock
                                                            color='warning'
                                                            attentions={[{ name: '', header: ['Link Name'], data: links.missingLiks.map(el => decodeURIComponent(el)).map(el => [el]) }]}
                                                            notes='Please upload the missing links'
                                                            buttonLabel='Missing links'
                                                            title={'Missing links ' + document.filename}
                                                            textAlign='left'
                                                        />
                                                    )}
                                                </Stack>
                                            )}
                                        </ListItem>

                                    ))}
                                </Box>
                            )}

                        </Stack>

                    </Stack>
                </CardContent>

            </Card>
        </ListItem >
    )
}
