import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';

import Refresh from '@mui/icons-material/Refresh'

import GenericUpload from './GenericUpload';

import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    GridOverlay,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import Pagination from '@mui/material/Pagination';
import Primarytheme from './theme';

const axios = require('axios');

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    if (pageCount > 1) return (
        <Pagination
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}

        />
    )
    else return null
}


export default function AssetImage({ auth, conf, dataTable, folderObjList, handleRefreshTable, inDashboard = false }) {

    //console.log('asset folders obj list', folderObjList)
    const [selectionModel, setSelectionModel] = useState([]);

    // messagge many items to delete
    const [openAsset, setOpenAsset] = useState(false);

    const handleCloseAsset = (event, reason) => {
        setOpenAsset(false);
    };

    const handleSelectedRows = ({ selection }) => {
        setSelectionModel(selection)
        //console.log('selectionModel ', selectionModel)
    }

    const deleteFileS3 = async ({ keyList, manyItemsFlag }) => {
        const name = dataTable.name;
        const sharedProjectId = folderObjList.filter(obj => obj.name === name)[0].sharedProjectId;

        // console.log('delete ', keyList)
        if (manyItemsFlag) setOpenAsset(true);

        let dynamoArrRequest = [];
        for (let i = 0; i < keyList.length; i++) {
            dynamoArrRequest.push(keyList[i]);

            if (i % 25 === 0 || i === keyList.length - 1) {
                const delReq = await axios({
                    method: "post",
                    url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
                    headers: {
                        'user_id': auth.userState.ssmParams.USER_ID,
                        "content-type": "application/json",
                        "x-api-key": auth.userState.ssmParams.X_API_KEY,
                        "action": "delete-batch"
                    },
                    data: { keyList: dynamoArrRequest, manyItemsFlag, sharedProjectId }
                })
                console.log('resp delete:', i, delReq)

                dynamoArrRequest = [];
            }

        }

        setSelectionModel([]);
        // refresh!!
        await new Promise(resolve => setTimeout(resolve, 1000));
        await handleRefreshTable()
        setOpenAsset(false)
    }



    const CustomToolbar = () => {
        return (
            <Stack direction='row' justifyContent="space-between" alignItems="center" mb='1em'>
                <Stack direction='row' spacing={2} justifyContent="space-between" alignItems="center">
                    <Typography variant='body2' >
                        items: {dataTable.nItems}
                    </Typography>
                    <Typography variant='body2'  >
                        total size: {(dataTable.size >= 1073741824) ? Number(dataTable.size / 1073741824).toFixed(2) + ' GB' : Math.round(dataTable.size / 1048576) + ' MB'}
                    </Typography>
                </Stack>


                <Stack direction='row' spacing={3} justifyContent='flex-end' alignItems='center' >
                    <Stack direction='row' spacing={1} justifyContent='flex-end' alignItems='center' >
                        <GridToolbarColumnsButton sx={{ pt: 0.3, pb: 0.3, color: Primarytheme.palette.neutral.main, ":hover": { color: Primarytheme.palette.primary.main }, border: '1px solid' }} />
                        <GridToolbarFilterButton sx={{ pt: 0.3, pb: 0.3, color: Primarytheme.palette.neutral.main, ":hover": { color: Primarytheme.palette.primary.main }, border: '1px solid' }} />
                        <GridToolbarDensitySelector sx={{ pt: 0.3, pb: 0.3, color: Primarytheme.palette.neutral.main, ":hover": { color: Primarytheme.palette.primary.main }, border: '1px solid' }} />
                        <GridToolbarExport sx={{ pt: 0.3, pb: 0.3, color: Primarytheme.palette.neutral.main, ":hover": { color: Primarytheme.palette.primary.main }, border: '1px solid' }} />
                    </Stack>
                    <Stack direction='row' spacing={1} justifyContent='flex-end' alignItems='center' >
                        <Button
                            onClick={async () => await deleteFileS3({ keyList: selectionModel.map((idRow) => `${conf.bucket}###${idRow}`), manyItemsFlag: selectionModel.length > 100 ? true : false })}
                            variant='contained'
                            size='small'
                            startIcon={<DeleteIcon />}
                            disabled={selectionModel.length === 0}
                        >
                            Delete
                        </Button>
                        <IconButton onClick={handleRefreshTable} > <Refresh /> </IconButton>
                    </Stack>
                </Stack>
            </Stack >


        );
    }

    return (
        <Stack direction='column' spacing={2} mt={inDashboard ? 0 : '1em'} >
            <Card elevation={inDashboard ? 0 : 1}>
                <CardContent sx={{ '&:last-child': { m: inDashboard ? 0 : undefined, p: inDashboard ? 0 : undefined, paddingBottom: '0.5em' } }}>
                    {!inDashboard &&
                        <Typography component='div' variant='body' color='primary' sx={{ mb: '1em' }}>
                            <b>Upload New {dataTable.name}</b>
                        </Typography>
                    }

                    <GenericUpload
                        auth={auth.userState}
                        bucket={conf.customBucket ? conf.customBucket : conf.bucket}
                        project_id={conf.project_id}
                        folderObjList={folderObjList}
                        selectedFolder={dataTable.name}
                        refresh={handleRefreshTable}
                        direction={inDashboard ? 'column' : 'row'}
                    />

                </CardContent>
            </Card >

            {!inDashboard && (
                <Card>
                    <CardContent sx={{ '&:</CardContent>last-child': { paddingBottom: '0.5em' } }}>
                        <Typography component='div' variant='body' color='primary' >
                            <b>Explore {dataTable.name}</b>
                        </Typography>

                        <DataGrid
                            key={dataTable.name}
                            rows={dataTable.rows}
                            columns={dataTable.columns}
                            autoHeight

                            checkboxSelection

                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10

                                    }
                                }
                            }}
                            pageSizeOptions={[5]}

                            onRowSelectionModelChange={(selection) => handleSelectedRows({ selection })}
                            rowSelectionModel={selectionModel}
                            slots={{
                                pagination: CustomPagination,
                                toolbar: CustomToolbar,

                            }}

                            sx={{
                                border: '0px',
                                '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
                                '& .MuiDataGrid-cell:focus': { outline: 'none' },
                                '& .MuiDataGrid-columnHeaderCheckbox:focus': { outline: 'none' },
                                '& .MuiDataGrid-cellCheckbox': { outline: 'none', color: 'red' },
                            }}
                        />
                    </CardContent>
                </Card >
            )}

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={openAsset}
                //autoHideDuration={6000}
                onClose={handleCloseAsset}
                message='We are deleting many files. If the process stops, reload the page and try again.'
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseAsset}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </Stack>
    )


}