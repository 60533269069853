import { useEffect, useState, useMemo } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { getAssetFoldersFromConf } from '../helpers/assets';
import { retrieveSignedUrl } from '../helpers/operations';
import GenericUpload from './GenericUpload';

const axios = require('axios');

export default function AssetsLaunch({ conf, setConf, auth, authAll }) {

    const [refreshAsset, setRefreshAsset] = useState(1);

    const [assets, setAssets] = useState({

        fetching: true,
        refreshing: true,
        uploading: false,

        tag: null,
        refresh: 1,

        // navbar
        folders: [],

        // solo per images
        objectList: [],
        dataTables: [],
        dataTablesId: {},
        //selectionModel: [],

        // solo per indd
        inddValues: {},



    })

    const fetchS3Objects1 = function ({ src, name, type, tags, sharedProjectId, obj = {}, i = 0 }) {
        let action = type === 'img' ? 'query-items-dynamo' : type;
        let body = {};

        if (action === 'additional-data') {
            action = 'query-items-dynamo';
            body = {
                project_id: sharedProjectId ? sharedProjectId : src.split('/').slice(1, 3).join('/'),
                selectedFolder: name,
                fullSize: obj[src] ? obj[src].fullSize : 0,
                filterUser: authAll.policy.filterUser ? authAll.user : false,
            }
        }
        if (action === 'indesign') {
            action = 'query-indesign-dynamo';
        }
        if (type === 'img') {
            body = {
                project_id: sharedProjectId ? sharedProjectId : src.split('/').slice(1, 3).join('/'),
                selectedFolder: name,
                LastEvaluatedKey: obj[src] ? obj[src].LastEvaluatedKey : undefined,
                fullSize: obj[src] ? obj[src].fullSize : 0,
                filterUser: authAll.policy.filterUser ? authAll.user : false
            }
        }

        return axios({
            method: "post",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': auth.USER_ID,
                "content-type": "application/json",
                "x-api-key": auth.X_API_KEY,
                "src": src,
                "tags": JSON.stringify(tags),
                "action": action,
                "project_id": src.split('/').slice(1, 3).join('/'),
                "selectedFolder": name,
                "LastEvaluatedKey": obj[src] ? JSON.stringify(obj[src].LastEvaluatedKey) : undefined,
                "lastId": obj[src] ? obj[src].lastId : 0,
                "fullSize": obj[src] ? obj[src].fullSize : 0,
            },
            data: { ...body }
        })
            .then(resp => {
                if (type === 'img') {
                    if (!resp.data.LastEvaluatedKey && !obj[src]) {
                        return { data: { s3KeyList: resp.data.s3KeyList, fullSize: resp.data.fullSize }, type }
                    } else {
                        if (!obj[src] || (obj[src] && resp.data.LastEvaluatedKey !== undefined)) {
                            obj[src] = {
                                fullSize: resp.data.fullSize,
                                s3KeyList: obj[src] ? [...obj[src].s3KeyList, ...resp.data.s3KeyList] : resp.data.s3KeyList,
                                startAfter: resp.data.lastKey,
                                src,
                                type,
                                tags,
                                LastEvaluatedKey: resp.data.LastEvaluatedKey ? resp.data.LastEvaluatedKey : undefined,
                            }

                            i++;
                            // recusrive call
                            return fetchS3Objects1({ src, type, name, tags, sharedProjectId, obj, i });
                        } else {

                            // return with the updated data
                            obj[src] = {
                                fullSize: resp.data.fullSize,
                                s3KeyList: obj[src] ? [...obj[src].s3KeyList, ...resp.data.s3KeyList] : resp.data.s3KeyList,
                                startAfter: resp.data.lastKey,
                                src,
                                type,
                                tags,
                                LastEvaluatedKey: resp.data.LastEvaluatedKey ? resp.data.LastEvaluatedKey : 0,
                            }

                            return { data: { s3KeyList: obj[src].s3KeyList, fullSize: obj[src].fullSize }, type }
                        }
                    }
                } else if (type === 'indesign') {
                    // return as before
                    if (!resp.data.LastEvaluatedKey && !obj[src]) {
                        return { data: resp.data, type }
                    } else if ((!obj[src] && resp.data.LastEvaluatedKey) || (obj[src] && resp.data.LastEvaluatedKey)) {
                        // manage receiving tags
                        let tagsToObj = {};
                        if (obj[src]) {
                            const oldTags = obj[src].tagOrderedObj;
                            const newTags = resp.data.tagOrderedObj;
                            tagsToObj = { ...obj[src].tagOrderedObj };
                            for (const key in newTags) {
                                if (oldTags[key]) {
                                    let tagsArr = [...oldTags[key], ...newTags[key]];
                                    tagsArr = tagsArr.filter((value, index, self) => index === self.findIndex((t) => (t.name === value.name)))
                                    tagsToObj[key] = [...tagsArr];
                                } else {
                                    tagsToObj[key] = [...newTags[key]];
                                }
                            }
                        } else {
                            tagsToObj = { ...resp.data.tagOrderedObj };
                        }

                        // caso particolare con last evalueted key
                        if (obj[src] && obj[src].inddList) {
                            const respInddMap = new Map();
                            resp.data.inddList.forEach(el => respInddMap.set(el.reqid, el));

                            const objInddMap = new Map();
                            obj[src].inddList.forEach(el => objInddMap.set(el.reqid, el));

                            for (const [reqid, value] of objInddMap) {
                                if (respInddMap.has(reqid)) {
                                    objInddMap.set(reqid, {
                                        ...value,
                                        fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                        links: [...value.links, ...respInddMap.get(reqid).links],
                                        bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                        filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                        idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                        indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                        jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                        keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                        lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                        linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                        reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                        size: value.size ? value.size : respInddMap.get(reqid).size,
                                        tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                        unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                    })

                                    // cancella reqid già processati
                                    respInddMap.delete(reqid);
                                }
                            }
                            for (const [reqid, value] of respInddMap) {
                                objInddMap.set(reqid, {
                                    ...value,
                                    fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                    links: [...value.links, ...respInddMap.get(reqid).links],
                                    bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                    filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                    idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                    indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                    jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                    keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                    lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                    linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                    reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                    size: value.size ? value.size : respInddMap.get(reqid).size,
                                    tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                    unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                })
                            }

                            obj[src].inddList = [];
                            objInddMap.forEach((value, key, map) => obj[src].inddList.push(value))
                        }

                        obj[src] = {
                            ...obj[src],
                            fullSize: (obj[src] ? Number(obj[src].fullSize) : 0) + Number(resp.data.fullSize),
                            inddList: (obj[src] && obj[src].inddList) ? obj[src].inddList : [...resp.data.inddList],
                            tagOrderedObj: tagsToObj,
                            LastEvaluatedKey: resp.data.LastEvaluatedKey ? JSON.parse(resp.data.LastEvaluatedKey) : undefined,
                        }

                        i++;
                        // recusrive call
                        return fetchS3Objects1({ src, name, type, tags, sharedProjectId, obj, i });
                    } else {
                        let tagsToObj = {};
                        if (obj[src]) {
                            const oldTags = obj[src].tagOrderedObj;
                            const newTags = resp.data.tagOrderedObj;
                            tagsToObj = { ...obj[src].tagOrderedObj };
                            for (const key in newTags) {
                                if (oldTags[key]) {
                                    let tagsArr = [...oldTags[key], ...newTags[key]];
                                    tagsArr = tagsArr.filter((value, index, self) => index === self.findIndex((t) => (t.name === value.name)))
                                    tagsToObj[key] = [...tagsArr];
                                } else {
                                    tagsToObj[key] = [...newTags[key]];
                                }
                            }

                        } else {
                            tagsToObj = { ...resp.data.tagOrderedObj };
                        }

                        // caso particolare con last evalueted key
                        if (obj[src] && obj[src].inddList) {
                            const respInddMap = new Map();
                            resp.data.inddList.forEach(el => respInddMap.set(el.reqid, el));

                            const objInddMap = new Map();
                            obj[src].inddList.forEach(el => objInddMap.set(el.reqid, el));

                            for (const [reqid, value] of objInddMap) {
                                if (respInddMap.has(reqid)) {
                                    objInddMap.set(reqid, {
                                        ...value,
                                        fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                        links: [...value.links, ...respInddMap.get(reqid).links],
                                        bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                        filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                        idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                        indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                        jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                        keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                        lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                        linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                        reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                        size: value.size ? value.size : respInddMap.get(reqid).size,
                                        tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                        unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                    })

                                    // cancella reqid già processati
                                    respInddMap.delete(reqid);
                                }
                            }

                            for (const [reqid, value] of respInddMap) {
                                objInddMap.set(reqid, {
                                    ...value,
                                    fonts: [...value.fonts, ...respInddMap.get(reqid).fonts],
                                    links: [...value.links, ...respInddMap.get(reqid).links],
                                    bucket: value.bucket ? value.bucket : respInddMap.get(reqid).bucket,
                                    filename: value.filename !== 'undefined' ? value.filename : respInddMap.get(reqid).filename,
                                    idml: value.idml ? value.idml : respInddMap.get(reqid).idml,
                                    indd: value.indd ? value.indd : respInddMap.get(reqid).indd,
                                    jpg: value.jpg ? value.jpg : respInddMap.get(reqid).jpg,
                                    keyName: value.keyName ? value.keyName : respInddMap.get(reqid).keyName,
                                    lastModified: value.lastModified ? value.lastModified : respInddMap.get(reqid).lastModified,
                                    linksCheckArr: value.linksCheckArr ? value.linksCheckArr : respInddMap.get(reqid).linksCheckArr,
                                    reqid: value.reqid ? value.reqid : respInddMap.get(reqid).reqid,
                                    size: value.size ? value.size : respInddMap.get(reqid).size,
                                    tags: value.tags ? value.tags : respInddMap.get(reqid).tags,
                                    unusedLinksArr: value.unusedLinksArr ? value.unusedLinksArr : respInddMap.get(reqid).unusedLinksArr,
                                })
                            }

                            obj[src].inddList = [];
                            objInddMap.forEach((value, key, map) => obj[src].inddList.push(value))
                        }

                        obj[src] = {
                            ...obj[src],
                            fullSize: Number(resp.data.fullSize),
                            inddList: obj[src].inddList ? obj[src].inddList : [...resp.data.inddList],
                            tagOrderedObj: tagsToObj,
                            LastEvaluatedKey: resp.data.LastEvaluatedKey ? JSON.parse(resp.data.LastEvaluatedKey) : undefined,
                        }

                        return { data: { ...obj[src] }, type }
                    }
                } else {
                    // return as before
                    return { data: resp.data, type }
                }
            })
            .catch(err => {
                return (err)
            });

    }

    const handleChangeCover = async function ({ event, id, value }) {
        // console.log('before change', 'id ', id, 'value ', value, event, conf.selectedAssets);
        const newConfSelected = [...conf.selectedAssets];
        if (value) {
            if (value.Name) {
                if (id >= 0 && assets.dataTables[id].data.s3KeyList) {
                    const file = assets.dataTables[id].data.s3KeyList.filter(file => file.Name === value.Name)[0]; // new standard unique name for uploaded file using webapp
                    console.log('file', file)
                    newConfSelected[id].value = file.Name;
                    newConfSelected[id].filename = file.Name;
                    newConfSelected[id].fullPath = file.fullPath;

                    const response = await retrieveSignedUrl({ key: file.fullPath.split('###')[1], bucket: file.fullPath.split('###')[0], ssmParams: auth })
                    newConfSelected[id].jpg = response.data.signedUrl;
                }
            }
            if (value.name) {
                // set Name property to avoid conflicts between selected files and uploaded files
                value.Name = value.name
                if (id >= 0 && assets.dataTables[id].data.s3KeyList) {
                    const file = assets.dataTables[id].data.s3KeyList.filter(file => file.name === value.Name)[0]; // new standard unique name for uploaded file using webapp
                    console.log('uploaded file', value)
                    newConfSelected[id].value = value.name;
                    newConfSelected[id].filename = value.name;
                    newConfSelected[id].fullPath = `${conf.customBucket ? conf.customBucket : conf.bucket}###${conf.project_id}/${newConfSelected[id].path.split('/').pop()}/${value.name}`;
                    if (authAll.policy.filterUser) {
                        newConfSelected[id].fullPath = `${conf.customBucket ? conf.customBucket : conf.bucket}###${conf.project_id}/${newConfSelected[id].path.split('/').pop()}/${authAll.user}/${value.name}`;
                    }

                    let key = newConfSelected[id].fullPath.split('###')[1];
                    const response = await retrieveSignedUrl({ key: key, bucket: newConfSelected[id].fullPath.split('###')[0], ssmParams: auth })
                    newConfSelected[id].jpg = response.data.signedUrl;
                }
            }
        } else {
            newConfSelected[id].value = '';
            newConfSelected[id].filename = '';
            newConfSelected[id].fullPath = '';
            newConfSelected[id].jpg = undefined;
        }

        setConf({ ...conf, selectedAssets: newConfSelected });
    }

    const refreshPage = function () {
        setRefreshAsset(!refreshAsset)
    }

    // retrieve conf after selected project
    useEffect(() => {
        // setAssets({ ...assets, refreshing: true })
        const assetFolders = getAssetFoldersFromConf(conf, authAll).filter(src => src.type === 'img' && src.inLaunch);
        //  setAssets({ ...assets, folders: assetFolders, conf: projectConf })
        const promises = [];
        for (const src of assetFolders) {
            promises.push(fetchS3Objects1({
                src: src.s3,
                name: src.name,
                type: 'img',
                sharedProjectId: src.sharedProjectId,
            }))
        }

        Promise.all(promises)
            .then(folderResList => {
                setAssets({ ...assets, folders: assetFolders, dataTables: folderResList, conf, fetching: false, refreshing: false })
            })
    }, [refreshAsset]);

    return (
        <Box>
            <Box sx={{ marginTop: '2em' }} >
                {conf.selectedAssets.map((doc, id) => {
                    //if(!doc.tags) doc.tags = ['tag'];
                    return (

                        <Stack key={doc.folder} direction='row' sx={{ height: '10em' }} spacing={10} alignItems='start'>
                            <Box sx={{ marginBottom: '1em' }}>
                                <Box mb='1em'>
                                    <b>{doc.folder}</b>
                                </Box>
                                <Box>
                                    {assets.fetching && <Skeleton variant="rectangular" animation="wave" height={40} sx={{ width: 400 }} />}
                                    {!assets.fetching && (
                                        <Stack direction='row' spacing={0}>
                                            <Autocomplete id="assets-file"
                                                disablePortal
                                                disabled={assets.dataTables[id].data.s3KeyList.length === 0}
                                                options={assets.dataTables[id].data.s3KeyList}
                                                sx={{ width: 'fit-content', minWidth: 300 }}
                                                clearOnEscape
                                                // disableCloseOnSelect
                                                value={doc.value}
                                                onChange={(event, value) => handleChangeCover({ event, id, value })}
                                                size='small'
                                                isOptionEqualToValue={(option, value) => option.Name === value.Name}
                                                getOptionLabel={(o) => (typeof o === "string" ? o : "")}
                                                autoHighlight
                                                renderInput={(params) => <TextField {...params} label={'Choose a file'} />}
                                                renderOption={(props, option) =>
                                                    option.Name ? <li {...props}>{option.Name}</li> : null
                                                }
                                            />
                                            <GenericUpload
                                                auth={authAll}
                                                bucket={conf.customBucket ? conf.customBucket : conf.bucket}
                                                project_id={conf.project_id}
                                                folderObjList={assets.folders}
                                                selectedFolder={doc.folder}
                                                refresh={refreshPage}
                                                direction={'row'}
                                                inLaunch={true}
                                                idAsset={id}
                                                handleChangeCover={handleChangeCover}
                                            />
                                            {/* <IconButton onClick={refreshPage} > <Refresh /> </IconButton> */}

                                        </Stack>
                                    )}
                                </Box>

                            </Box>

                            <CoverPreview doc={doc} isFetching={assets.fetching} auth={auth} />

                        </Stack>
                    )

                })}
            </Box>

        </Box >

    )
}



function CoverPreview({ doc, isFetching, auth }) {
    const [loaded, setLoaded] = useState(false);

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        // console.log('loaded');
        setLoaded(true);
    }

    function onLoad() {
        // console.log('loaded');
        setLoaded(true);
    }

    useEffect(() => {
        if (loaded) setLoaded(false);
    }, [doc.jpg])

    const pdf = useMemo(() => doc.jpg, [doc.jpg]);


    if (doc.jpg) {
        const ext = doc.value.split('.').pop().toLowerCase();
        if (ext === 'jpg' || ext === 'png' || ext === 'pdf') {
            return (
                <Box sx={{ height: '100%' }}>
                    {(ext === 'jpg' || ext === 'png') && (
                        <Paper square={true} sx={{ height: 150, display: loaded ? 'block' : 'none' }}>
                            <img
                                style={{ height: 150 }}
                                onLoad={onLoad}
                                src={doc.jpg}
                                alt={''}
                            />
                        </Paper>
                    )}
                    {ext === 'pdf' && (
                        <Paper square={true} sx={{ height: 150, display: loaded ? 'block' : 'none' }}>
                            <Document
                                file={pdf}
                                onLoadSuccess={onDocumentLoadSuccess}
                                noData=''
                            >
                                <Page
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    loading={null}
                                    pageNumber={pageNumber}
                                    //width={150}
                                    height={150}
                                    // scale={1}
                                    error={() => null}
                                />
                            </Document>
                        </Paper>)}

                    {!loaded && <CircularProgress variant='indeterminate' size={25} sx={{ mt: '2.8em', ml: '2.5em' }} />}
                </Box>
            )
        } else return null
    }
    else return null
}