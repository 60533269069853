const Crypto = require("crypto-js");
const axios = require('axios');
const { REACT_APP_REGION } = process.env;

// url firmata, default expire 60 secondi
export const retrieveSignedUrl = ({ key, bucket, Expires = 180, attachment = false, ssmParams, customDomain }) => {

    // console.log('bucket ', bucket, 'key', realKey)
    let flag_Attachment = false;
    if (attachment || key.search(/\.idml|\.indd|\.txt|\.xls|\.xlsx/) !== -1) flag_Attachment = true;
    //console.log('flag_attach', flag_Attachment)
    return axios({
        method: "post",
        url: 'https://api.pagination.com/vpc/operations',
        headers: {
            'user_id': ssmParams.USER_ID,
            "content-type": "application/json",
            "x-api-key": ssmParams.X_API_KEY,
        },
        data: {
            Bucket: bucket,
            Key: key,
            Expires, // default 180 secondi, da passare il tempo in [sec]
            ResponseContentDisposition: flag_Attachment ? 'attachment; filename ="' + key.split('/').pop() + '"' : undefined,
            customDomain,
        }
    })
}

export const getFiles = ({ key, config }) => {

    const dateISOString = new Date(+new Date() + 864e5).toISOString();
    const xAmzDate = dateISOString.split('-').join('').split(':').join('').split('.').join('');
    const dateYMD = dateISOString.split('T')[0].split('-').join('');

    let flag_Attachment = false;
    if (config.attachment || key.search(/\.idml|\.indd|\.txt|\.xls|\.xlsx/) !== -1) flag_Attachment = true;

    let url = `https://documents.pagination.com/${config.dirName ? config.dirName + "/" : ""}${key.split('/').slice(0, -1).join('/')}/${encodeURIComponent(key.split('/').pop())}?`;
    url += "content-type=application/json";
    url += "&Date=" + xAmzDate;
    url += "&X-Amz-Date" + xAmzDate;
    url += "&Authorization" + getSignature(config, dateYMD, getPolicy(config, dateYMD, xAmzDate, dateISOString));
    if (flag_Attachment) url += '&response-content-disposition=attachment;filename=' + encodeURIComponent(key.split('/').pop());

    //console.log(url);

    if (flag_Attachment) {
        var link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        window.open(url);
    }

    return url;
}


// download a key from s3, bucket is default state
export const getFileFromS3 = ({ key, bucket, customBucket = 'documents.pagination.com', attachment = false, ssmParams }) => {
    // console.log('get from s3 ', customBucket, bucket, key);
    const keyNoBucket = key.replace(`${bucket}/`, '');
    const config = {
        bucketName: bucket,
        dirName: '',
        region: REACT_APP_REGION,
        accessKeyId: ssmParams.ACCESS_KEY,
        secretAccessKey: ssmParams.SECRET_ACCESS_KEY,
        attachment
    }
    const oldBuckets = [
        'bluedock',
        'cadetblue',
        // 'bluenavy',
        'pagination-test',
        'skyblue',
        'blueeagle'
    ];
    // caso particolare il progetto geox (catalogo sellin arricchito) con frontend in bluenavy ma output in documents.pagination.com
    if (oldBuckets.indexOf(bucket) !== -1 || (bucket === 'bluenavy' && customBucket !== 'documents.pagination.com')) {
        const windowdPdf = attachment === false ? window.open('about:blank') : undefined;
        retrieveSignedUrl({ key: keyNoBucket, bucket, attachment, ssmParams })
            .then(response => {
                //console.log(response.data.signedUrl, response.data.download)
                if (windowdPdf) {
                    windowdPdf.location.href = response.data.signedUrl
                } else {
                    var link = document.createElement("a");
                    link.href = response.data.signedUrl;
                    link.target = "_blank";
                    link.rel = "noopener noreferrer";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch(err => console.log(err))
    } else {
        // console.log(customBucket)
        retrieveSignedUrl({ key: keyNoBucket, bucket, attachment, ssmParams, Expires: customBucket ? 86400 : undefined, customDomain: customBucket }) // da cambiare con variabile bucket
            .then(response => {
                if (!attachment) {
                    window.open(response.data.signedUrl);
                } else {
                    var link = document.createElement("a");
                    link.href = response.data.signedUrl;
                    link.target = "_blank";
                    link.rel = "noopener noreferrer";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch(err => console.log(err))
    }
}

function getSignature(config, date, policyBase64) {
    return signature(config, date, policyBase64);
}

function signature(config, date, policyEncoded) {
    return Crypto.HmacSHA256(policyEncoded, getSignatureKey(config.secretAccessKey, date, config.region)).toString(Crypto.enc.Hex);
};

function getSignatureKey(key, dateStamp, regionName) {
    const kDate = Crypto.HmacSHA256(dateStamp, "AWS4" + key);
    const kRegion = Crypto.HmacSHA256(regionName, kDate);
    const kService = Crypto.HmacSHA256("s3", kRegion);
    const kSigning = Crypto.HmacSHA256("aws4_request", kService);
    return kSigning;
};

function getPolicy(config, dateYMD, xAmzDate, dateISOString) {
    //Returns a base64 policy;
    return new Buffer(JSON.stringify(policy(config, dateYMD, xAmzDate, dateISOString))).toString("base64").replace(/\n|\r/, "");
}

function policy(config, dateYMD, xAmzDate, dateISOString) {
    return {
        expiration: dateISOString,
        conditions: [{ bucket: config.bucketName }, ["starts-with", "$key", `${config.dirName ? config.dirName + "/" : ""}`], { acl: "public-read" }, ["starts-with", "$Content-Type", ""], { "x-amz-meta-uuid": "14365123651274" }, { "x-amz-server-side-encryption": "AES256" }, ["starts-with", "$x-amz-meta-tag", ""], {
            "x-amz-credential": `${config.accessKeyId}/${dateYMD}/${config.region}/s3/aws4_request`
        }, { "x-amz-algorithm": "AWS4-HMAC-SHA256" }, { "x-amz-date": xAmzDate }]
    };
};