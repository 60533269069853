import Primarytheme from './theme';
import React, { useState, useRef } from 'react';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@mui/material/Chip';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { getContentType } from '../helpers/assets';

const axios = require('axios');

const {
    REACT_APP_S3_BUCKET,
    REACT_APP_REGION,
} = process.env;

export default function GenericUpload({ auth, bucket, project_id, folderObjList, selectedFolder, refresh, direction = 'row', inLaunch = false, idAsset, handleChangeCover }) {

    const fileInputRef = useRef(null);
    //console.log('uploading component ', folderObj, selectedFolder)
    const [openAsset, setOpenAsset] = useState({ open: false, message: '', duration: 6000 });

    const [selectedAssets, setSelectedAssets] = useState({});
    const [assetProgress, setAssetProgress] = useState(0);

    const [isLoadingAsset, setIsLoadingAsset] = useState({});

    const updateObjState = (stateObj, setStateObj, value, folder) => {
        //console.log('value ', value)
        setStateObj(stateObj => {
            const updatedStateObj = { ...stateObj };
            updatedStateObj[folder] = value;
            return updatedStateObj;
        })
    }

    const handleCloseAsset = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAsset({ open: false, message: '', duration: 6000 });
    };

    const handleClearAssetsAll = (folder) => {

        updateObjState(selectedAssets, setSelectedAssets, [], folder);
        setAssetProgress(0)
        updateObjState(isLoadingAsset, setIsLoadingAsset, false, folder);
    };
    const handleAssetsInput = async (event, folder, folderPath, inLaunch) => {
        //event.preventDefault();
        updateObjState(selectedAssets, setSelectedAssets, event.target.files, folder);

        if (inLaunch) {
            await handleUploadAsset([event.target.files[0]], folder, folderPath)
            handleChangeCover({ event, id: idAsset, value: event.target.files[0] })
        }
    }




    const handleUploadAsset = async (assets, folderName, folderPath) => {

        //updateObjState(assetProgress, setAssetProgress, 10, folderName);
        updateObjState(isLoadingAsset, setIsLoadingAsset, true, folderName);

        const dirName = folderPath.replace(`${bucket}/`, '');
        const sizing = { total: 0, uploaded: [] };

        for (const f of assets) {
            sizing.total += f.size;
        }

        sizing.uploaded.push(...Array(assets.length).fill(0))

        if (assets.length > 100) {
            setOpenAsset({ open: true, message: 'There are many images to upload, please do not close the page until the process finishes.', duration: null })
        }

        const promises = [];
        let dynamoArr = [];
        for (let id = 0; id < assets.length; id++) {
            const f = assets[id];
            promises.push(
                new Promise((resolve, reject) => {
                    auth.s3.upload({
                        Body: f, //files[i],
                        Bucket: bucket,
                        Key: dirName + '/' + f.name,//files[i].name,
                        ContentType: getContentType(f.name.split('.').pop())
                    })
                        .on('httpUploadProgress', evt => {
                            sizing.uploaded[id] = evt.loaded;
                            const loadedPart = sizing.uploaded.reduce((partialSum, a) => partialSum + a, 0)
                            // console.log(id, loadedPart, sizing.total, loadedPart / sizing.total)
                            setAssetProgress(loadedPart / sizing.total * 100);
                        })
                        .send((err, data) => {
                            if (err) {
                                console.log('error uploading data, please refresh', err)
                                return reject();
                            } else {
                                // set up obj for dynamo
                                const sharedProjectId = folderObjList.filter(obj => obj.name === selectedFolder)[0].sharedProjectId;
                                dynamoArr.push({
                                    ...data,
                                    uploadDate: new Date().getTime(),
                                    name: f.name,
                                    lastModified: f.lastModified,
                                    lastModifiedDate: f.lastModifiedDate,
                                    size: f.size,
                                    type: f.type,
                                    selectedFolder,
                                    project_id: sharedProjectId ? sharedProjectId : project_id, //key
                                    reqId: f.name,
                                    fileCategory: selectedFolder,
                                    tags: 'empty',
                                    ...(auth.policy.filterUser) ? { filterUser: auth.user } : {},
                                })
                                return resolve();
                            }
                        })
                })
            )


            if ((id + 1) % 100 === 0 || id === assets.length - 1) {
                await Promise.all(promises).catch(err => console.log(err));
                promises.length = 0

                console.log('reached 25 items in S3, id: ', id);

                // upload in dynamo
                let dynamoArrRequest = [];
                for (let i = 0; i < dynamoArr.length; i++) {
                    dynamoArrRequest.push(dynamoArr[i]);

                    if ((i + 1) % 100 === 0 || i === dynamoArr.length - 1) {
                        console.log('reached 25 items in dynamo, id: ', id, 'start dynamo request', i);

                        const putReq = await axios({
                            method: "post",
                            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
                            headers: {
                                'user_id': auth.ssmParams.USER_ID,
                                "content-type": "application/json",
                                "x-api-key": auth.ssmParams.X_API_KEY,
                                "action": 'new-item-dynamo-batch',
                            },
                            data: JSON.stringify(dynamoArrRequest)
                        })
                        console.log('resp dynamo upload:', id, 'step', i)

                        dynamoArrRequest = [];
                    }
                }
                dynamoArr = [];
            }
        }

        console.log('upload completed');
        setOpenAsset({ open: true, message: 'Upload completed', duration: 6000 })
        await refresh();
        handleClearAssetsAll(folderName);
    }

    const handleButtonClick = (e) => {
        // Trigger a click on the hidden file input
        fileInputRef.current.click();
    };

    return (
        <Box id='pino'>
            {folderObjList && folderObjList.filter(f => f.name === selectedFolder).map(f => {
                const folder = f.name, folderPath = f.s3;
                return (
                    <Stack direction={direction}
                        justifyContent="flex-start"
                        alignItems={direction === 'row' ? 'center' : 'flex-start'}
                        spacing={2}
                        key={folder}
                    >
                        {inLaunch === false && (
                            <Stack direction='row' spacing={2} alignItems='center' >
                                <Button
                                    variant="outlined"
                                    component="label"
                                    color='primary'
                                    size='small'

                                >
                                    Select files
                                    <FileUploadIcon color='primary' />
                                    <input
                                        type="file"
                                        multiple
                                        hidden
                                        accept={f.ext}
                                        onChange={(event) => handleAssetsInput(event, folder)}
                                        onClick={(event) => {
                                            event.target.value = null //per poter selezionare lo stesso file di seguito
                                        }}

                                    />
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    component="label"
                                    color='secondary'
                                    size='small'
                                    loading={isLoadingAsset[folder]}
                                    loadingIndicator={
                                        <Stack direction='row' alignItems='center'>
                                            <CircularProgress color="inherit" size={16} variant='determinate' value={assetProgress} />
                                            <Typography variant='body2' ml='0.5em'>{assetProgress.toFixed(0)}%</Typography>
                                        </Stack>
                                    }
                                    disabled={selectedAssets[folder] && selectedAssets[folder].length > 0 ? false : true}
                                    onClick={() => handleUploadAsset(selectedAssets[folder], folder, folderPath)}>

                                    UPLOAD
                                </LoadingButton>

                            </Stack  >
                        )}
                        {inLaunch && (
                            <Stack direction='row' spacing={2} alignItems='center' >
                                <input
                                    type="file"
                                    // multiple
                                    hidden
                                    accept={f.ext}
                                    ref={fileInputRef}
                                    onChange={(event) => handleAssetsInput(event, folder, folderPath, inLaunch)}
                                    onClick={(event) => {
                                        event.target.value = null //per poter selezionare lo stesso file di seguito
                                    }}

                                />
                                <Button
                                    variant="outlined"
                                    component="label"
                                    color='primary'
                                    size='small'
                                    fullWidth
                                    sx={{ height: '3.05em' }}
                                    onMouseDown={(event) => handleButtonClick(event)}
                                >
                                    Upload New File
                                    <FileUploadIcon color='primary' />
                                </Button>
                            </Stack>
                        )}
                        {/* <Box> <LinearProgress variant="determinate" value={40} /></Box> */}
                        {selectedAssets[folder] && selectedAssets[folder].length > 0 && (
                            <Chip label={`${selectedAssets[folder].length} files selected`} variant="outlined" onDelete={() => handleClearAssetsAll(folder)} sx={{ borderRadius: 1 }} />
                        )}

                    </Stack>
                )

            })
            }



            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={openAsset.open}
                autoHideDuration={openAsset.duration}
                onClose={handleCloseAsset}
                message={openAsset.message}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseAsset}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />

            <Typography sx={{ marginBottom: '1em' }}
                variant="body2"
                color={Primarytheme.palette.neutral.dark}> </Typography>
        </Box >

    )
}