import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';


import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Refresh from '@mui/icons-material/Refresh'
//import Masonry from '@mui/lab/Masonry';
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import RequestBlockSquare from './utility/RequestBlockSquare';
import RequestTable from './utility/RequestTable';
import CustomFilterMenu from './utility/CustomFilterMenu';
import Picker from './utility/Pickers'
import Tooltip from '@mui/material/Tooltip'
import { Grid, Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import FilterSourceData from './utility/FilterSourceData';

import logoNotFound from '../logos/stars.png'

import TagsFilter from './utility/TagsFilter';

import statiRiconosciuti from './helpers/statiRiconosciuti';

const axios = require('axios');

const intervalTick = 60000; //[ms]

//const marginsHistory = { marginTop: '10em', marginBottom: '2em', marginLeft: '2em', marginRight: '2em' };

const marginsHistory = { marginTop: 96, marginBottom: 32, marginLeft: 32, marginRight: 32 };




export default function History({ auth, parentForceRefresh, itemsToShow, inDashBoard = false }) {

  // init 
  const now = new Date();
  const todayEnd = (new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)).getTime();

  const navbarRef = useRef();

  // query params
  const queryParams = new URLSearchParams(window.location.search);

  // Stato
  // app padre sta prcurando le credenziali e i dati utente
  const [credentials, setCredentials] = useState({
    ...auth.userState,
    attached_projects: auth.userState.userItem ? auth.userState.userItem.attached_projects : [],
  });

  // page layout selection
  const [layout, setLayout] = React.useState('cards');

  const handleLayout = (event, newLayout) => {
    if (newLayout !== null) setLayout(newLayout);
  };

  const historyStart = getThatDaysBackMidnight(now, auth.userState.isAdmin ? 1 : 31 * 6);

  const [items, setItems] = useState({
    items: [],
    tagOrderedObj: {},
    tagAllObj: {},
    tagOrderedList: [],
    blockToShowCompl: inDashBoard ? 2 : 12,
    blockToShowFailed: inDashBoard ? 2 : 12,
    retrievingData: true
  }); // stato progetti

  const [refresh, setRefresh] = useState(0); // stato progetti

  // stato filtri 
  const [menuListSelected, setListMenu] = useState([]);
  const [listaStatiSelected, setListaStati] = useState([]);
  //const [listaUsersSelected, setUsersStati] = useState([]);

  const [datePicker, setDatePicker] = useState({
    dateStart: parseDateQuery(queryParams.get('dateStart'), historyStart, 'start'),
    dateEnd: parseDateQuery(queryParams.get('dateEnd'), todayEnd, 'end')
  })

  const [origin, setOrigin] = useState(queryParams.get('reqid') || '');

  const showMoreItemsCompl = () => {
    setItems(items => { return { ...items, blockToShowCompl: items.blockToShowCompl + 12 } })
  }
  const showMoreItemsFailed = () => {
    setItems(items => { return { ...items, blockToShowFailed: items.blockToShowFailed + 12 } })
  }

  const handleDateChange = (date, index) => {
    const dateEpoch = (new Date(date)).getTime();
    if (index === 0) {
      return setDatePicker({ ...datePicker, dateStart: dateEpoch });
    }
    else {
      return setDatePicker({ ...datePicker, dateEnd: dateEpoch });
    }
  };


  const handleClickTagChip = ({ key, id }) => {
    const newtagOrderedObj = { ...items.tagOrderedObj };

    //console.log(key, id)
    newtagOrderedObj[key][id] = { label: newtagOrderedObj[key][id].label, active: !newtagOrderedObj[key][id].active }
    if (id !== 0 && newtagOrderedObj[key][id].active) newtagOrderedObj[key][0].active = false;

    //console.log('handleflipchip', newtagOrderedObj)
    setItems({ ...items, tagOrderedObj: newtagOrderedObj })
  }

  const setIntervalNode = () => {
    if (true) {
      return setInterval(
        () => refreshPage(),
        intervalTick
      );
    }
  }

  const handleProjectListSelected = (event, values) => {
    setListMenu(values);
  };


  const changeItem = ({ reqId, newItem }) => {
    //console.log('change item ', newItem.status,);
    // first check for delete
    if (newItem.delete === 'delete') {
      setItems(items => {
        const tmpItems = [...items.items].filter(el => el.reqId !== reqId);
        return {
          ...items,
          items: tmpItems
        }
      })
    }
    else if (statiRiconosciuti.terminati.has(newItem.status)) {
      newItem.moved = true;
      setItems(items => {
        const tmpItems = [...items.items].map(el => {
          if (el.reqId !== reqId) return el;
          else return newItem
        })
        //console.log('item refreshed ', reqId, tmpItems)
        return {
          ...items,
          items: tmpItems
        }
      })
    }
  }



  // set auto refreh on each refresh
  useEffect(() => {
    const intervalState = setIntervalNode();
    return () => {
      clearInterval(intervalState);
    }
  }, []);


  useEffect(() => {
    if (parentForceRefresh !== undefined && parentForceRefresh > 0) {
      //console.log('effetto refresh ', parentForceRefresh)
      refreshPage()
    }
  }, [parentForceRefresh]);


  // retrieve dinamo upon refresh
  useEffect(() => {

    if (credentials.userItem && credentials.userItem.attached_projects) {
      const listaProgetti = menuListSelected.length === 0 ? credentials.attached_projects : menuListSelected;
      if (listaProgetti.length > 0) {
        setItems({ ...items, retrievingData: true });
        axios({
          method: "get",
          url: 'https://api.pagination.com/vpc/operations',
          headers: {
            'user_id': credentials.ssmParams.USER_ID,
            "content-type": "application/json",
            "x-api-key": credentials.ssmParams.X_API_KEY,
            "is_admin": credentials.isAdmin,
            //"reqId": "39f072c9-91e5-41dc-b7e2-fa892c810bf5",
            //"forceRefresh": true
            "project_ids": listaProgetti.join('@'),
            "datestart": datePicker.dateStart,
            "dateend": datePicker.dateEnd,
            "stati": listaStatiSelected.length === 0 ? 'tutti' : listaStatiSelected.join('@'),
            "filter_user": auth.userState.policy.filterUser ? auth.userState.email : false,
          }
        })
          .then(resp => {
            //console.log(resp.data)
            setItems({ ...items, items: resp.data.items, retrievingData: false, tagOrderedList: resp.data.tagOrderedList, tagOrderedObj: resp.data.tagOrderedObj, tagAllObj: resp.data.tagAllObj })
          })
          .catch(err => {
            setItems({ ...items, retrievingData: false })
            console.log(err)
          });
      }
    }

  }, [credentials.attached_projects, refresh, menuListSelected, listaStatiSelected, datePicker, layout]); // si dovrebbe refreshare ogni volta che cambia lo stato di authObj


  // refresh quando l'app padre aggiorna i progetti dopo F5
  useEffect(() => {
    //console.log('is fetching è cambiato ', auth.userState.isFetching)
    if (auth.userState.userItem) {
      //setAttachedProjects(credentials.userItem.attached_projects)
      setCredentials({ ...auth.userState, isFetching: auth.userState.isFetching, attached_projects: auth.userState.attached_projects })
    }
  }, [auth, auth.userState.isFetching, auth.userState.attached_projects]);

  // function that increase number refresh so that useEffect is called again
  function refreshPage() {
    if (refresh === 0) setRefresh(refresh => refresh + 1)
    else setRefresh(refresh => refresh - 1)
    //console.log('sto refreshando la pagina ', refresh, 'start ', dateStart, 'end ', dateEnd)
  }


  const history = useHistory();

  const redirect = ({ path, search }) => {
    history.push({
      pathname: path,
      search: search, //'?color=blue'
    });
  }


  const handleOriginFilter = (originText) => {
    setOrigin(originText)
    redirect({ path: '/history', search: `?reqid=${originText}` })
  }


  // MAIN STUFF

  // RENDER 
  return (
    <Box>
      {!inDashBoard && (
        <Box>
          <AppBar position="fixed" sx={{ top: '3em', zIndex: 1, }} >
            <Stack ref={navbarRef} direction='row' justifyContent="space-around" alignItems='center'
              sx={{ pt: '1em', pl: '32px', pr: '32px', pb: '1em', background: 'white' }}>
              <Picker nome={'start-date'} dataStato={datePicker.dateStart} handleDateChange={(date) => handleDateChange(date, 0)} />
              <Picker nome={'end-date'} dataStato={datePicker.dateEnd} handleDateChange={(date) => handleDateChange(date, 1)} />
              <CustomFilterMenu label='project id' originalList={credentials.attached_projects} menuListSelected={menuListSelected} handleChange={handleProjectListSelected} width={'20%'} />
              <FilterSourceData label='sourcedata or reqId' originQuery={origin} handleChange={handleOriginFilter} />

              <Box sx={{ m: 1, position: 'relative' }}>
                <IconButton onClick={refreshPage} > <Refresh /> </IconButton>
                {items.retrievingData &&
                  <CircularProgress sx={{ position: 'absolute', top: 0, left: 0, zIndex: 0, }} color='primary' />}
              </Box>
              <ToggleButtonGroup
                value={layout}
                exclusive
                onChange={handleLayout}
                aria-label="Choose the page layout"
              >
                <ToggleButton value="cards" aria-label="Cards">
                  <GridViewRoundedIcon />
                </ToggleButton>
                <ToggleButton value="table" aria-label="table">
                  <ViewListRoundedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            {items.retrievingData && <LinearProgress variant='indeterminate' sx={{ height: 2 }} />}
          </AppBar>


          <Stack direction='column' sx={{ margin: { ...marginsHistory, marginTop: marginsHistory.marginTop + ((navbarRef.current && navbarRef.current.clientHeight) || 0) }, }} spacing={4}>
            {!items.retrievingData && items.items.length === 0 && (
              <Stack direction='column' alignItems='center' sx={{ mt: '1em' }}>
                <img src={logoNotFound} style={{ width: "auto", height: "300px" }} />
                <Typography variant="h6" mt={5}>
                  Your history is empty
                </Typography>
              </Stack>
            )}

            {/* {items.tagOrderedList.length > 0 && <TagsFilter tagOrderedObj={items.tagOrderedObj} tagOrderedList={items.tagOrderedList} handleClickTagChip={handleClickTagChip} />} */}
            {layout === 'cards' && (
              <>
                <BlockList title='In progress'
                  requestList={filterItem({ items, validStati: statiRiconosciuti.running, origin, tagsObj: items.tagOrderedObj })}
                  inDashBoard={inDashBoard}
                  changeItem={changeItem}
                  user={auth.userState} attached_projects={auth.userState.attached_projects}
                />
                <BlockList title='Completed'
                  requestList={filterItem({ items, validStati: statiRiconosciuti.completati, origin, tagsObj: items.tagOrderedObj })}
                  inDashBoard={inDashBoard}
                  showMore={showMoreItemsCompl}
                  limit={items.blockToShowCompl}
                  changeItem={changeItem}
                  user={auth.userState} attached_projects={auth.userState.attached_projects}
                />
                <BlockList title='Failed'
                  requestList={filterItem({ items, validStati: statiRiconosciuti.falliti, origin, tagsObj: items.tagOrderedObj })}
                  inDashBoard={inDashBoard}
                  showMore={showMoreItemsFailed}
                  limit={items.blockToShowFailed}
                  changeItem={changeItem}
                  user={auth.userState} attached_projects={auth.userState.attached_projects}
                />
              </>
            )}
            {layout === 'table' && items.items.length > 0 && (
              <>
                <RequestTable
                  refresh={refresh}
                  refreshPage={refreshPage}
                  requestList={items.items}
                  changeItem={changeItem}
                  user={auth.userState} attached_projects={auth.userState.attached_projects}
                />
              </>
            )}
          </Stack>


        </Box>
      )}


      {
        inDashBoard && (
          <Box sx={{ margin: 0 }}>
            {!items.retrievingData && items.items.length === 0 && (
              <Typography variant='h5' color='primary' sx={{ marginTop: '1em', marginBottom: '1em', }}>
                Your history is empty
              </Typography>
            )}
            {items.retrievingData && items.items.length === 0 && (
              <Stack>
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" animation="wave" height={200} />
              </Stack>
            )}


            <BlockList title=''
              requestList={items.items.slice(0, itemsToShow)} //0.0045*window.innerWidth-2.5164
              inDashBoard={inDashBoard}
              changeItem={changeItem}
              user={auth.userState} attached_projects={auth.userState.attached_projects}
            />

          </Box>
        )
      }

    </Box >
  );


}



function getThatDaysBackMidnight(now, daysBack = 1) {
  const yesterdayId = now.getDate() - daysBack;
  const tempDate = new Date();
  tempDate.setDate(yesterdayId);
  tempDate.setHours(0, 0, 0, 0);
  return tempDate.getTime();
}

function BlockList({ title, requestList, showMore, limit, inDashBoard, changeItem, user, attached_projects }) {
  const breaks = inDashBoard ? { xs: 12, sm: 6, md: 6, lg: 3, xl: 3 } : { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }

  if (requestList.length > 0) {

    return (
      <Box sx={{}}>
        <Typography variant='h5' color='primary' sx={{ marginBottom: '0.5em', }}>
          {title}
        </Typography>
        <Grid container spacing={2}
          //justifyContent={inDashBoard ? "center" : undefined}
          sx={{ flexGrow: 1 }}>
          {requestList.slice(0, limit).map(request => {
            //console.log(request.reqId, request.status)
            return (
              <Grid item key={request.reqId} {...breaks}  >
                <RequestBlockSquare key={request.version} changeItem={changeItem} requestParent={request} user={user} attached_projects={attached_projects} />
              </Grid>
            )
          }
          )}
        </Grid>
        {requestList.length > limit && (
          <Tooltip title="Show more">
            <IconButton sx={{ position: 'relative', left: '50%', mb: '1em' }} onClick={showMore} >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Tooltip>
        )}



      </Box>
    )
  } else {
    return null
  }
}



function filterItem({ items, validStati, tagsObj, origin }) {

  //console.log('origin',origin)
  const originLower = origin ? origin.toLowerCase() : origin;

  return items.items.filter(item => {
    const itemOrigin = item.origin ? item.origin.toLowerCase() : '';
    let isStato = validStati.has(item.status);
    if (item.errors && item.errors.length && validStati.has('validation failed')) isStato = true;
    if (item.errors && item.errors.length && validStati.has('pagination complete')) isStato = false;

    const isOrigin = (origin === '') || itemOrigin.indexOf(originLower) !== -1 || item.reqId === origin;

    let hasTags = false;

    if (!item.tagsObj || Object.keys(tagsObj).length === 0) hasTags = true;
    else {
      // console.log(el.key)
      try {
        if (item.tagsObj.some(el => tagsObj[el.name][0].active || (tagsObj[el.name] && tagsObj[el.name].filter(f => f.label === el.value && f.active).length > 0))) hasTags = true
      } catch (err) {
        console.log(err)
      }
    }
    //else if (item.tagsObj.some(el => tagsObj[el.key] && tagsObj[el.key].filter(f => f.active).length > 0)) hasTags = true;

    return isStato && isOrigin && hasTags;

  })

}

function parseDateQuery(date, alternative, type) {

  const numero = parseInt(date);
  if (isNaN(numero)) return alternative
  else return type === 'start' ? numero - 20 * 60 * 1000 : numero
}