//import "./styles.css";
import { React, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    Label,
    ScatterChart, Scatter,
    ResponsiveContainer
} from "recharts";

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Refresh from '@mui/icons-material/Refresh'
import CustomFilterMenu from './utility/CustomFilterMenu';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Picker from './utility/Pickers'


const axios = require('axios');


const now = new Date();
const todayEnd = (new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)).getTime();
const historyStart = getYesterdayMidnight(now);

const marginsHistory = { marginTop: '10em', marginBottom: 0, marginLeft: '2em', marginRight: '2em' };


export default function Charts({ auth }) {

    const [credentials, setCredentials] = useState({
        ...auth.userState,
        attached_projects: auth.userState.userItem ? auth.userState.userItem.attached_projects : []
    });

    const [items, setItems] = useState([]); // stato progetti
    const [refresh, setRefresh] = useState(0); // stato progetti

    const [retrievingData, setRetrievingData] = useState(true);
    // stato filtri 
    const [menuListSelected, setListMenu] = useState([]);

    const [datePicker, setDatePicker] = useState({ dateStart: historyStart, dateEnd: todayEnd })

    //const [interval, setInterval] = useState({})

    const handleDateChange = (date, index) => {
        const dateEpoch = (new Date(date)).getTime();
        if (index === 0) return setDatePicker({ ...datePicker, dateStart: dateEpoch });
        else return setDatePicker({ ...datePicker, dateEnd: dateEpoch });
    };


    const handleProjectListSelected = (event, values) => {
        setListMenu(values);
    };


    // retrieve dinamo upon refresh
    useEffect(() => {

        if (auth.userState.userItem && auth.userState.userItem.attached_projects) {

            const listaProgetti = menuListSelected.length === 0 ? auth.userState.userItem.attached_projects : menuListSelected;
            //console.log('ids: ', listaProgetti);
            // console.log('stati sele ', listaStatiSelected)
            //console.log(credentials.isAdmin)
            if (true) {
                setRetrievingData(true)
                axios({
                    method: "get",
                    url: 'https://api.pagination.com/vpc/operations',
                    headers: {
                        'user_id': credentials.ssmParams.USER_ID,
                        "content-type": "application/json",
                        "x-api-key": credentials.ssmParams.X_API_KEY,
                        "is_admin": credentials.isAdmin,
                        //"reqId": "39f072c9-91e5-41dc-b7e2-fa892c810bf5",
                        //"forceRefresh": true
                        "project_ids": listaProgetti.join('@'),
                        "datestart": datePicker.dateStart,
                        "dateend": datePicker.dateEnd,
                        "stati": 'tutti',
                    }
                })

                    .then(resp => {

                        const things = [...resp.data.items];
                        console.log('numero reqIds ', things.length)
                        const data = [];
                        const dayQueueObj = {};

                        const interv = 30 * 60 * 1000;

                        for (const el of things) {
                            if (el.origin && !isNaN(el.queuedTime)) {
                                for (const splitName in el.splitDoneObj) {
                                    const split = el.splitDoneObj[splitName];
                                    if (split.endTime) {
                                        data.push({ t: split.endTime, queueTime: split.queuedTime / 1000 / 60, humanTime: (new Date(split.endTime)).toString(), id: el.project_id })
                                    }
                                }
                            }
                        }

                        /*                     for (const el of things) {
                                                if (el.origin && !isNaN(el.queuedTime)) {
                                                    const date = new Date(el.startTime);
                                                    const time = [date.getDate(), date.getMonth() + 1, date.getFullYear()].toString();
                                                    if (!dayQueueObj[time]) dayQueueObj[time] = { giorno: undefined, coda: [] };
                                                    dayQueueObj[time].coda.push(el.queuedTime / el.n_split);
                                                    dayQueueObj[time].giorno = time;
                                                }
                                            }
                    
                                            for (const date in dayQueueObj) {
                                                const queueMedio = dayQueueObj[date].coda.reduce((a, b) => (a + b)) / dayQueueObj[date].coda.length / 1000 / 60
                                                data.push({
                                                    date,
                                                    giorno: dayQueueObj[date].giorno,
                                                    queueMedio
                                                })
                                            }
                    
                                            data.sort((a, b) => (a[2] < b[2] && a[1] < b[1] && a[0] < b[0]) ? 1 : -1); */

                        /*                        const colorSplit = {};
                                               let counter = 0;
                                               for (const el of things) {
                                                   const originSet = new Set();
                                                   if (el.origin && Object.keys(el.splitDoneObj).length > 0) {
                                                       const originName = el.origin.split('/').pop().slice(0, 14);
                                                       let realOrigin;
                                                       if (originSet.has(originName)) {
                                                           realOrigin = originName + counter.toString();
                                                           counter++;
                                                       } else {
                                                           realOrigin = originName
                                                           originSet.add(originName)
                                                       }
                                                       const request = { name: realOrigin };
                                                       for (const split in el.splitDoneObj) {
                                                           el.splitDoneObj[split].queuedTime = el.splitDoneObj[split].queuedTime ? el.splitDoneObj[split].queuedTime / 1000 : undefined;
                                                           // if (el.splitDoneObj[split].t_syncSrc) request[`t_sync_src_${el.splitDoneObj[split].name}`] = el.splitDoneObj[split].t_syncSrc;
                                                           if (el.splitDoneObj[split].t_split) request[`${el.splitDoneObj[split].name}`] = el.splitDoneObj[split].t_split;
                                                           // if (el.splitDoneObj[split].queuedTime) request[`t_queue_${el.splitDoneObj[split].name}`] = el.splitDoneObj[split].queuedTime ? el.splitDoneObj[split].queuedTime / 1000 : 0;
                                                           colorSplit[`${el.splitDoneObj[split].name}`] = `#${Math.floor(Math.random() * 16777215).toString(16)}`
                                                       }
                                                       data.push(request)
                                                   }
                       
                                               }
                                               console.log(colorSplit) */
                        //setColors(colorSplit)
                        setRetrievingData(false)
                        setItems(data)
                        console.log('numero di punti ', items.length)

                    })
                    .catch(err => console.log(err))
            }

        }

    }, [credentials.attached_projects, refresh, menuListSelected, datePicker.dateStart, datePicker.dateEnd]); // si dovrebbe refreshare ogni volta che cambia lo stato di authObj

    useEffect(() => {
        console.log('cambiato auth in charts')
        if (auth.userState.userItem) {
            //setAttachedProjects(credentials.userItem.attached_projects)
            setCredentials({ ...auth.userState, isFetching: auth.userState.isFetching, attached_projects: auth.userState.userItem.attached_projects })
        }
    }, [auth]);



    // function that increase number refresh so that useEffect is called again
    function refreshPage() {
        if (refresh === 0) setRefresh(refresh + 1)
        else setRefresh(refresh - 1)
        console.log('sto refreshando la pagina ',)
    }


    const history = useHistory();
    const redirect = (dst) => {
        history.push(dst);
    }

    return (
        <Box>
            <AppBar position="fixed" sx={{ top: '3em', zIndex: 1, }} >
                <Stack direction='row'
                    sx={{
                        //top: '3em',
                        paddingTop: '1em',
                        paddingLeft: '32px',
                        paddingRight: '32px',
                        paddingBottom: '1em',
                        background: 'white',
                        /*             position: 'sticky',
                                    zIndex: 1, */
                        justifyContent: "space-around"
                    }}>
                    <Picker nome={'start-date'} dataStato={datePicker.dateStart} handleDateChange={(date) => handleDateChange(date, 0)} />
                    <Picker nome={'end-date'} dataStato={datePicker.dateEnd} handleDateChange={(date) => handleDateChange(date, 1)} />
                    <CustomFilterMenu label='project id' originalList={credentials.attached_projects} menuListSelected={menuListSelected} handleChange={handleProjectListSelected} width={'30%'} />

                    <Box sx={{ m: 1, position: 'relative' }}>
                        <IconButton onClick={refreshPage} > <Refresh /> </IconButton>
                        {retrievingData &&
                            <CircularProgress sx={{ position: 'absolute', top: 0, left: 0, zIndex: 0, }} color='primary' />}
                    </Box>
                </Stack>
            </AppBar>


            <Box sx={{ margin: marginsHistory }}>
                <ScatterChart
                    width={1200}
                    height={500}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                >
                    <CartesianGrid />
                    <XAxis
                        type="number"
                        dataKey="t"
                        name="split start time"
                        interval="preserveStartEnd"
                        domain={['dataMin - 1000', 'dataMax + 1000']}
                        tickFormatter={formatXAxis}
                        label={{ value: 'data e ora', position: 'bottom', offset: 5 }}

                    />
                    <YAxis type="number" dataKey="queueTime" name="queue time per split" unit="'" label={{ value: 'Tempo [min]', angle: -90, position: 'insideLeft' }} />
                    <Tooltip content={< CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />
                    <Scatter name="data" data={items} fill="#8884d8" />
                </ScatterChart>

            </Box>

            <iframe src="http://dragon.pagination.com:5601/app/kibana#/dashboard/53aacb80-dfa9-11e9-ae3b-7de6523212fb?embed=true&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-30m,mode:quick,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:11,i:'3',w:48,x:0,y:0),id:'80b26190-dfa6-11e9-ae3b-7de6523212fb',panelIndex:'3',type:visualization,version:'7.0.1'),(embeddableConfig:(),gridData:(h:6,i:'5',w:7,x:7,y:26),id:e6e088e0-5f3d-11ea-ae3b-7de6523212fb,panelIndex:'5',type:visualization,version:'7.0.1'),(embeddableConfig:(),gridData:(h:6,i:'6',w:7,x:14,y:26),id:'88257850-abec-11ea-ae3b-7de6523212fb',panelIndex:'6',type:visualization,version:'7.0.1'),(embeddableConfig:(),gridData:(h:6,i:'7',w:7,x:0,y:26),id:ab55a1e0-abf3-11ea-ae3b-7de6523212fb,panelIndex:'7',type:visualization,version:'7.0.1'),(embeddableConfig:(),gridData:(h:15,i:'8',w:24,x:0,y:11),id:'70e93a50-ac8d-11e9-ae3b-7de6523212fb',panelIndex:'8',type:visualization,version:'7.0.1'),(embeddableConfig:(),gridData:(h:15,i:'9',w:24,x:24,y:11),id:c641c6a0-dee4-11e9-ae3b-7de6523212fb,panelIndex:'9',type:search,version:'7.0.1')),query:(language:kuery,query:''),timeRestore:!f,title:'Pagination+Status+DashBoard',viewMode:view)" height="600" width="100%"></iframe>
        </Box >

    );
}

const CustomTooltip = ({ active, payload }) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`t : ${formatToolTip(payload[0].payload.t, 't')}`}</p>
                <p className="label">{`queueTime : ${formatToolTip(payload[0].payload.queueTime)}`}</p>
                <p className="label">{`id : ${payload[0].payload.id}`}</p>

            </div>
        );
    }
    return null;
};
function formatXAxis(value) {
    // console.log(value)
    return (new Date(value)).toString().replace('GMT+0100 (Ora standard dell’Europa centrale)', '')
}

function formatToolTip(value, name) {
    if (name === 't') return (new Date(value)).toString().replace('GMT+0100 (Ora standard dell’Europa centrale)', '')
    else return value.toFixed(2)
}

function getYesterdayMidnight(now) {
    const yesterdayId = now.getDate() - 1;
    const tempDate = new Date();
    tempDate.setDate(yesterdayId);
    tempDate.setHours(0, 0, 0, 0);
    return tempDate.getTime();
}

