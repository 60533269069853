
import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import TextField from '@mui/material/TextField';
import { Snackbar } from '@mui/material';

import { Typography, Stack, FormControlLabel, Switch } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const axios = require('axios');

const limits = {
    custom_message: {
        messageChecked: false,
        message: ''
    },
    deactivation: {
        date: (new Date()).getTime(),
        dateChecked: false,
        disableLaunch: '',
    }
}

const limitInfo = {
    custom_message: {
        order: 1,
        descr: 'custom message from Billing.'
    },
    deactivation: {
        order: 2,
        descr: 'disable the project on a specific date.'
    }
}



export default function ProjectDisable({ auth, projects }) {

    const [projectParams, setProjectParams] = useState({
        project: null,
        isPutting: false,
        isGetting: false,

        folders: [],
        conf: {
            deactivation: {
                date: (new Date()).getTime(),
                dateChecked: false,
                disableLaunch: '',
            }
        }
    })

    const [snack, setSnack] = useState({
        open: false,
        creationMessage: ''
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            open: false,
            creationMessage: ''
        });
    };

    const handleChangeProject = async (event, value) => {
        if (!value) {
            resetProject();
            setProjectParams({
                conf: {
                    deactivation: {
                        date: (new Date()).getTime(),
                        dateChecked: false,
                        disableLaunch: '',
                    },
                },
                folders: [],
                project: undefined,
                isGetting: false
            });
        }
        else {
            setProjectParams({
                ...projectParams,
                isGetting: true,
            });
            const currentConf = await fetchConf({ project_id: value })
            const currentFolders = await fetchFolders({ prefix: `${currentConf.data.projectConf.bucket}/${currentConf.data.projectConf.prefix}` })
            setProjectParams({
                conf: {
                    deactivation: {
                        date: (new Date()).getTime(),
                        dateChecked: false,
                        disableLaunch: '',
                    },
                    ...currentConf.data.projectConf
                },
                folders: [
                    ...currentFolders.data.folders
                ],
                project: value,
                isGetting: false
            })
        }
    }

    const fetchConf = async ({ project_id }) => {
        const [customer, project_name] = project_id.split('/').slice(0, 2);
        return axios.get('https://api.pagination.com/vpc/projects-webapp',
            {
                headers: {
                    'user_id': auth.userState.user,
                    "content-type": "application/json",
                    "x-api-key": auth.userState.ssmParams.X_API_KEY,
                    "project_id": project_id,
                    "project_name": project_name,
                    "customer": customer,
                }

            },
        );
    }

    const fetchFolders = async ({ prefix }) => {
        return axios({
            method: "get",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': auth.userState.user,
                "content-type": "application/json",
                "x-api-key": auth.userState.ssmParams.X_API_KEY,
                "prefix": prefix,
                "action": 'fetch-folders'
            },
        }).catch(err => {
            //console.log('err fetch folder', err);
        })
    }

    const updateParameters = async () => {
        setProjectParams({ ...projectParams, isPutting: true });
        // call lambda
        axios({
            method: "put",
            url: auth.userState.ssmParams.UPDATE_CONF_URL,
            headers: {
                'x-api-key': auth.userState.ssmParams.UPDATE_CONF_KEY,
                'user': 'webapp',
                "content-type": "application/json",
            },
            data: projectParams,
            json: true
        }).then(response => {
            setSnack({ open: true, creationMessage: 'Parameters are updated!' })
            setProjectParams({ ...projectParams, isPutting: false });
        })


    }

    const handleDateChange = ({ date, text }) => {
        const conf = {
            ...projectParams.conf,
        };
        if (date) conf['deactivation'].date = date.getTime();

        if (text) conf['deactivation'].disableLaunch = text;

        setProjectParams({
            ...projectParams,
            conf,
        })
    }

    const handleChangeCheckOption = (event) => {

        const deactivation = {};
        deactivation.dateChecked = event.target.checked;
        if (!event.target.checked) deactivation.disableLaunch = '';

        setProjectParams({
            ...projectParams,
            conf: {
                ...projectParams.conf,
                deactivation: {
                    ...projectParams.conf.deactivation,
                    ...deactivation
                }
            },
        })
    }

    const resetProject = () => {
        setProjectParams({
            project: null,
            conf: {
                ...limits
            }
        });
    }


    return (

        <div>
            <Stack spacing={2} direction='column'>
                <Autocomplete id="project-params-selector"
                    disabled={projects.list.length === 0}
                    limitTags={5}
                    options={projects.list}
                    value={projectParams.project}
                    defaultValue='no limit'
                    onChange={handleChangeProject}
                    sx={{ width: '40%' }}
                    size='small'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={projects.retrieving ? 'loading...' : 'project-ids'} placeholder="" />
                    )}
                />

                {Object.keys(limits).sort((a, b) => limitInfo[a].order - limitInfo[b].order).map((k, value) => {

                    if (k === 'deactivation') {
                        return (
                            <Stack direction='row' spacing={2} key={k}>
                                <FormControlLabel
                                    label={<Typography variant='body1' sx={{ color: 'gray', ml: '1em' }} >
                                        Disable on date:
                                    </Typography>}
                                    disabled={!projectParams.project}
                                    control={<Switch checked={projectParams.conf.deactivation.dateChecked} onChange={handleChangeCheckOption} />}
                                />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        disabled={!projectParams.conf.deactivation.dateChecked}
                                        format="dd/MM/yyyy"
                                        //OpenPickerButtonProps={{component: 'label'}}
                                        label={'Disable date'}
                                        value={projectParams.conf.deactivation.date}
                                        onChange={(date) => handleDateChange({ date })}
                                        renderInput={(params) => <TextField {...params} sx={{ width: 160 }} />}
                                    />

                                </LocalizationProvider>
                                <TextField
                                    label={'disable message'}
                                    id={'disable message'}
                                    //helperText={k}
                                    value={projectParams.conf.deactivation.disableLaunch}
                                    //defaultValue={projectParams.conf[k]}
                                    size='medium'
                                    sx={{ width: '60%' }}
                                    onChange={(event) => {
                                        handleDateChange({ text: event.target.value })
                                    }}
                                    disabled={!projectParams.conf.deactivation.dateChecked}
                                />
                            </Stack>

                        )
                    }
                })
                }


            </Stack>
            <LoadingButton
                disabled={!projectParams.project}
                loading={projectParams.isPutting}
                //loadingPosition="start"
                //startIcon={<PersonAddAltIcon />}
                variant="contained"

                onClick={updateParameters}
                sx={{ marginTop: '2em' }}
            >
                Update parameters
            </LoadingButton>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snack.open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={snack.creationMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }

            />


        </div >
    )


}