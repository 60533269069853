
import { useEffect, useState, } from 'react';

import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';


import TextField from '@mui/material/TextField';

import Stack from '@mui/material/Stack';


import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';


import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';


import { encodeTag, decodeTag } from '../helpers/assets';


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


const axios = require('axios');


export default function AssetEditTags({ document, uploadOpen,
    tagsKeys, auth,
    handleUploadDialogClose, handleRefreshTable }) {

    const [uploadInddTag, setUploadInddTag] = useState(tagsKeys.map(t => { return { Key: t, Value: document.tags[t] } }))
    const [isUpdatingDoc, setIsUpdatingDoc] = useState(false);

    const handleChangeInddTag = (tag, value, document) => {
        const tagList = uploadInddTag.map(t => {
            if (t.Key === tag) {
                t.Value = value
            }
            return t
        });

        setUploadInddTag(tagList)
    }

    const handleUpdateDocument = () => {
      //  console.log('update doc', uploadInddTag, document.indd.fullPath)
      setIsUpdatingDoc(true)
        const uriEncodedTags = uploadInddTag.map(tagObj => { return { Key: encodeURIComponent(encodeTag(tagObj.Key)), Value: encodeURIComponent(encodeTag(tagObj.Value))}})
        //console.log('update tags', uriEncodedTags)
        return axios({
            method: "post",
            url: 'https://mjj82gfcl8.execute-api.eu-west-1.amazonaws.com/vpc/assets',
            headers: {
                'user_id': auth.userState.ssmParams.USER_ID,
                "content-type": "application/json",
                "x-api-key": auth.userState.ssmParams.X_API_KEY,
                "action": 'dynamo-update-tags',
                "docfullpath": encodeURIComponent(document.indd.fullPath),
                "project_id": document.indd.project_id,
                "key": document.indd.key,
                "tags": JSON.stringify(uriEncodedTags)
            },
        })

        .then((resp)=>{
            handleRefreshTable();
        })
            .catch(err => {
                console.log(err);
            })
            .finally(()=>{
                setIsUpdatingDoc(false);
                handleUploadDialogClose();
            });
    }

    //console.log('edit panel', docPath, tagsKeys)

    return <Dialog
        disableEscapeKeyDown={true}
        fullWidth
        maxWidth='md'
        onClose={handleUploadDialogClose}
        aria-labelledby="customized-dialog-title"
        open={uploadOpen}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleUploadDialogClose}>
            <b>{document.filename}</b>
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Stack spacing={2}>
                <Box>
                    {tagsKeys.map(tag => (
                        <TextField
                            key={tag}
                            label={tag}
                            //disabled={}
                            //helperText={tag}
                            value={uploadInddTag.filter(t => t.Key === tag).shift().Value}
                            size='small'
                            onChange={(event) => handleChangeInddTag(tag, event.target.value)}
                            sx={{ mr: '1em', mt: '1em' }}
                        //variant="filled"
                        />
                    ))}

                </Box>
                <Box>

                    <LoadingButton
                        variant="contained"
                        component="label"
                        color='secondary'
                        loading={isUpdatingDoc}
                        loadingIndicator={<CircularProgress color="inherit" size={16} variant='indeterminate' />}
                        //disabled={selectedAssets[folder] && selectedAssets[folder].length > 0 ? false : true}
                        onClick={handleUpdateDocument}>

                        Update tags
                    </LoadingButton>
                </Box>
            </Stack>
        </DialogContent>

    </Dialog>
}